import utils from "@/helpers/utilidades";
import garantiasAdminApi from "../../../garantias_admin.api";


export default {

  index(paginado = true,filtros = {}) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas/fideicomisos/cesionarios`, filtros, paginado));
  },

  crear(fideicomiso, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios`, payload)
  }

  ,obtener(fideicomiso,cesionario) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios/${cesionario}`);
  },

  editar(fideicomiso,cesionario, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios/${cesionario}`, payload)
  },

  eliminar(fideicomiso,cesionario) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios/${cesionario}`)
  },

  //movimientos cesionarios

  indexMovimientos(fideicomiso, cesionario = null) {

    let endpoint = `${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios`
    endpoint +=  !cesionario ?  '/movimientos' : `/${cesionario}/movimientos`

    return garantiasAdminApi.api.get(endpoint);
  },

  crearMovimiento(fideicomiso,cesionario, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios/${cesionario}/movimientos`, payload)
  }

  ,obtenerMovimiento(fideicomiso,cesionario,movimiento) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios/${cesionario}/movimientos/${movimiento}`);
  },

  editarMovimiento(fideicomiso,cesionario,movimiento, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios/${cesionario}/movimientos/${movimiento}`, payload)
  },

  eliminarMovimiento(fideicomiso,cesionario,movimiento) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/cesionarios/${cesionario}/movimientos/${movimiento}`)
  },


}