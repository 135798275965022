import utils from "@/helpers/utilidades";
import garantiasAdminApi from "../../../garantias_admin.api";


export default {

  index(paginado = true,filtros = {}) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/personas/fideicomisos/beneficiarios`, filtros, paginado));
  },

  crear(fideicomiso, payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/beneficiarios`, payload)
  }

  ,obtener(fideicomiso,beneficiario) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/beneficiarios/${beneficiario}`);
  },

  editar(fideicomiso,beneficiario, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/beneficiarios/${beneficiario}`, payload)
  },

  eliminar(fideicomiso,beneficiario) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/fideicomisos/${fideicomiso}/beneficiarios/${beneficiario}`)
  },

}