
<template>

  <div>

    <div class="text-right mb-4" v-if="!solo_lectura">
      <button class="btn btn-sm btn-success" @click="agregarGarantia"> <i class='fa fa-plus mr-sm-1'></i>Agregar garantía</button>
    </div>
    <div id="container-listado-garantias">
      <table class="table table-sm table-striped">
        <thead>
        <tr class="bg-dark">
          <th scope="col"></th>
          <th scope="col">Identificador</th>
          <th scope="col">Calle</th>
          <th scope="col">Número exterior</th>
          <th scope="col">Número interior</th>
          <th scope="col">Colonia</th>
          <th scope="col">CP</th>
          <th scope="col">Municipio</th>
          <th scope="col">Estado</th>
          <th scope="col">Estatus</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!garantias.length"><td colspan="8" class="text-secondary text-center"> No se encontraron garantías para mostrar.</td></tr>
        <tr v-for="(domicilio, index) in garantias">
          <td>
            <button type="button" class="ml-3 btn btn-sm btn-primary" @click="editar_garantia(index)"><i  :class="!solo_lectura ? 'fa fa-edit' : 'fa fa-eye'"></i></button>
          </td>
          <td>{{ domicilio.external_id }}</td>
          <td>{{ domicilio.calle }}</td>
          <td>{{domicilio.numero_exterior}}</td>
          <td>{{(!domicilio.numero_interior ? '--' : domicilio.numero_interior)}}</td>
          <td>{{ domicilio.colonia }}</td>
          <td>{{ domicilio.cp }}</td>
          <td>{{ domicilio.municipio }}</td>
          <td>{{ domicilio.estado }} </td>
          <td class="text-uppercase">{{ domicilio.estatus }} </td>
        </tr>
        </tbody>
      </table>
    </div>



    <Modal v-if="openModalEliminarGarantia" :options="{width: '30vw',type:'error'}" @close="openModalEliminarGarantia = false">
      <div class="title">Eliminar garanía</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás la garantía {{
              garantia.calle + ' ' + garantia.numero_exterior + ' ' + (!garantia.numero_interior ? '' : garantia.numero_interior)
            }}
            <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_garantia"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <formulario-garantia v-if="formGarantia" :persona_id="persona_id" :credito_id="credito_id" :garantia_id="garantia.id" @guardar="agregar_garantia($event)" @cancelar="cancelar_seleccion" @actualizar="cargarGarantias" :solo_lectura="solo_lectura"></formulario-garantia>

    </div>

</template>

<script>
import CampoDinamico from "@/apps/garantias_app/components/CampoDinamico.vue";
import Modal from "@/components/Modal.vue";
import FormularioGarantia from "@/apps/garantias_app/pages/clientes/creditos/garantias/FormularioGarantia.vue";
import garantiasApi from "@/apps/garantias_app/api/personas/creditos/garantias.api";

export default {
  name: "GarantiasCredito"
  ,props:{persona_id: null, credito_id: null, solo_lectura: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
    }
  ,components: {FormularioGarantia, Modal, CampoDinamico}
  ,data() {
    return {
       garantias: []
      , formGarantia: false
      , index_garantia_seleccionada: null
      , openModalEliminarGarantia: false
    }
  }
  ,mounted() {

    if (this.persona_id && this.credito_id)
      this.cargarGarantias()

  }
  ,computed:{
    garantia(){
      return this.index_garantia_seleccionada == null ? {} : {...this.garantias[this.index_garantia_seleccionada]}
    }

  }
  ,methods:{

    agregarGarantia(){
      this.formGarantia = true
    },

    async cargarGarantias(){
      this.cancelar_seleccion()
      const dataSource = await garantiasApi.index(this.persona_id, this.credito_id)
      this.garantias = dataSource.data
    },

    editar_garantia(index){
      this.index_garantia_seleccionada = index
      this.formGarantia = true
    },

    cancelar_seleccion(){
      this.index_garantia_seleccionada = null
      this.formGarantia = false
      this.openModalEliminarGarantia = false
    }

    ,pre_eliminar_garantia(index){
      if (this.garantias.length > 1) {
        this.index_garantia_seleccionada = index
        this.openModalEliminarGarantia = true
      }else{
        this.$helper.showMessage('Eliminar garantia', 'No se puede eliminar la garantia, el credito debe tener al menos una agregada.', 'error', 'alert')
      }
    }

    ,async eliminar_garantia(){

      if (this.garantia.hasOwnProperty('id') && this.garantia.id !== null)
        await garantiasApi.eliminar(this.persona_id, this.credito_id, this.garantia.id)

      this.garantias.splice(this.index_garantia_seleccionada, 1)
      this.index_garantia_seleccionada = null
      this.openModalEliminarGarantia = false

      this.$helper.showMessage('Eliminar dirección', 'Dirección eliminada exitosamente.', 'success', 'alert')
    }

    ,async agregar_garantia(garantia){

      //Validar si es un nuevo garanta o edición
      if (this.index_garantia_seleccionada === null) {
        this.garantias.push(garantia)
      }else {
        this.$set(this.garantias, this.index_garantia_seleccionada, garantia)
        this.cancelar_seleccion()
      }


    }

  }
  ,  watch: {
    'credito_id': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.cargarGarantias()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
#container-listado-garantias{
  max-height: 400px;
  overflow-y: scroll;

  table th:first-child{
    width: 110px;
  }
}
</style>
