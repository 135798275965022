
<template>

  <div>

    <div class="container mb-5">
      <div class="text-right">
        <button class="btn btn-sm btn-success" @click="agregar" :disabled="input_solo_lectura"> <i class='fa fa-plus mr-sm-1'></i>Agregar beneficiario</button>
      </div>
    </div>

    <div id="container-listado">
      <table class="table table-sm table-striped">
        <thead>
        <tr class="bg-dark">
          <th scope="col">
          </th>
          <th scope="col">Identificador externo</th>
          <th scope="col">Nombre</th>
          <th scope="col">Tipo</th>
          <th scope="col">Porcentaje beneficio</th>
          <th scope="col">Estatus</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!beneficiarios.length"><td colspan="9" class="text-secondary text-center"> No se encontraron beneficiarios para mostrar.</td></tr>
        <tr v-for="(item, index) in beneficiarios">
          <td>
            <button type="button" class="ml-3 btn btn-sm btn-primary" @click="editar(index)"><i :class="!input_solo_lectura ? 'fa fa-edit' : 'fa fa-eye'"></i></button>
          </td>
          <td>{{ item.external_id }}</td>
          <td>{{ item.nombre }}</td>
          <td>{{ item.tipo }}</td>
          <td>{{item.porcentaje_beneficio}} %</td>
          <td class="text-uppercase">{{item.estatus}}</td>
        </tr>
        </tbody>
      </table>
    </div>



    <Modal v-if="openModalEliminarCesionario" :options="{width: '30vw',type:'error'}" @close="openModalEliminarCesionario = false">
      <div class="title">Eliminar beneficiario</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el beneficiario: {{ beneficiario.nombre }}
            <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_eliminiar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="formBeneficiario" :options="{width: '50vw',type:'info'}" @close="cerrar_modal_formulario">
      <div class="title">{{ !beneficiario.id ? 'Agregar Beneficiario' : 'Editar Beneficiario' }}</div>
      <div class="body">

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
        </div>

        <div class="row justify-content-center mt-4">

          <div class="col-12 form-group">
            <label for="external_id" class="form-label text-truncate d-block" title="Identificador externo">
              Identificador externo
            </label>
            <div class="text-bold" v-if="input_solo_lectura">
              <i v-if="!beneficiario.identificador_externo" class="text-danger">Valor no ingresado</i>
              <b v-else>{{beneficiario.identificador_externo}}</b>
            </div>
            <input v-else v-model="beneficiario.external_id" type="text" name="external_id" id="external_id" class="form-control">
          </div>

          <div class="col-12 form-group">
            <label for="tipo" class="form-label text-truncate d-block" title="Tipo">
              <i class="fa fa-check-circle text-primary"></i>
              Tipo
            </label>
            <div v-if="input_solo_lectura" class="text-bold"><b>{{tipo_beneficiario}}</b></div>
            <select v-else class="form-control" name="tipo" id="tipo"  v-model="beneficiario.tipo" @change="cambioTipo()" :disabled="!!beneficiario.id">
              <option :value="tipo.valor" v-for="tipo in tipos_beneficiarios.opciones">{{tipo.nombre}}</option>
            </select>
          </div>

          <div class="col-12 form-group" v-show="es_fondeador">
            <label for="tipo" class="form-label text-truncate d-block" title="Fondeador">
              <i class="fa fa-check-circle text-primary"></i>
              Fondeador
            </label>
            <div v-if="input_solo_lectura" class="text-bold"><b>{{fondeador}}</b></div>
            <select v-else class="form-control" name="beneficiario_id" id="beneficiario_id"  v-model="beneficiario.beneficiario_id" :disabled="!!beneficiario.id">
              <option :value="fondeador.id" v-for="fondeador in fondeadores">{{fondeador.nombre}}</option>
            </select>
          </div>

          <div class="col-12 form-group">
            <label for="nombre" class="form-label text-truncate d-block" title="Nombre">
              <i class="fa fa-check-circle text-primary"></i>
              Nombre
            </label>
            <div v-if="input_solo_lectura" class="text-bold"><b>{{beneficiario.nombre}}</b></div>
            <input v-else v-model="beneficiario.nombre" type="text" name="nombre" id="nombre" class="form-control">
          </div>

          <div class="col-12 form-group">
            <label for="porcentaje_beneficio" class="form-label text-truncate d-block" title="Porcentaje beneficio">
              <i class="fa fa-check-circle text-primary"></i>
              Porcentaje beneficio
            </label>
            <div v-if="input_solo_lectura" class="text-bold text-capitalize"><b>{{beneficiario.porcentaje_beneficio}}</b></div>
            <IC v-else v-model="beneficiario.porcentaje_beneficio" label="%" id="porcentaje_beneficio" name="porcentaje_beneficio"/>
          </div>

          <div class="col-12 form-group">
            <label for="beneficiario_estatus" class="form-label text-truncate d-block" title="Estatus">
              <i class="fa fa-check-circle text-primary"></i>
              Estatus
            </label>
            <div v-if="input_solo_lectura" class="text-bold text-capitalize"><b>{{beneficiario.estatus}}</b></div>
            <select v-else class="form-control" name="beneficiario_estatus" id="beneficiario_estatus"  v-model="beneficiario.estatus" >
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>

        </div>

      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto" v-if="!input_solo_lectura"><button class="btn btn-primary" @click="guardar"><i class="fa fa-check-square mr-sm-1"></i>{{ !beneficiario.id ? 'Guardar' : 'Guardar Cambios' }}</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
    </div>

</template>

<script>
import Modal from "@/components/Modal.vue";
import IC from "@/components/Form/InputCompuesto.vue";
import beneficiariosApi from "@/apps/garantias_app/api/personas/fideicomisos/beneficiarios/beneficiarios.api";
import utilidades from "../../../../helpers/utilidades";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";
import fondeadoresApi from "@/apps/garantias_app/api/fondeadores/fondeadores.api";

export default {
  name: "FideicomisoBeneficiarios"
  , props: {
    fideicomiso_id: null
    ,input_solo_lectura: {
      type: Boolean
      ,required: false
      ,default() {
        return false
      }
    }
  }
  , components: {IC, Modal}
  , data() {
    return {
      beneficiarios: []
      ,fondeadores: []
      ,tipos_beneficiarios: {nombre: 'Tipo', opciones: []}
      , formBeneficiario: false
      , index_seleccionado: null
      , openModalEliminarCesionario: false
      , es_fondeador: false
    }
  }
  , async mounted() {

    if (!this.tipos_beneficiarios.opciones.length)
     await this.cargarCatalogoTiposBeneficiarios();

    await this.cargarListado()

  }
  , computed: {
    utilidades() {
      return utilidades
    },
    beneficiario: {
     get: function (){
       return this.index_seleccionado == null ? {
         id: null
         , nombre: null
         , external_id: null
         , tipo: null
         , porcentaje_beneficio: null
         , beneficiario_id: null
         , estatus: null
       } : {...this.beneficiarios[this.index_seleccionado]}
     }
    }
    ,fondeador(){
      const item_fondeador = this.fondeadores.find(fondeador => fondeador.id === this.beneficiario.beneficiario_id)
      return !item_fondeador ? 'Desconocido' : item_fondeador.nombre
    }
    ,tipo_beneficiario(){
      const item_tipo_beneficiario = this.tipos_beneficiarios.opciones.find(tipo => tipo.valor === this.beneficiario.tipo)
      return !item_tipo_beneficiario ? 'Desconocido' : item_tipo_beneficiario.nombre
    }

  }
  , methods: {

    async cargarCatalogoTiposBeneficiarios(){
      const dataSource = await catalogosApi.buscar('tipos_beneficiarios')
      this.tipos_beneficiarios = dataSource.data

    },

    async cambioTipo() {
      this.es_fondeador = !!this.beneficiario.tipo && this.beneficiario.tipo.toLowerCase()  === 'fondeador'

      if (!this.es_fondeador)
        this.beneficiario.beneficiario_id = null

      if (this.es_fondeador && !this.fondeadores.length) {
        const filtros = {
          columns: 'id,nombre',
          filters: [{
            relacion: "and",
            columna: "estatus",
            operador: "not_equal_to",
            valor1: 'inactivo',
            valor2: null
          }]
        }
        const dataSource = await fondeadoresApi.index(false, filtros)
        this.fondeadores = dataSource.data
      }
    },

    agregar() {
      this.formBeneficiario = true
    },

    async cargarListado() {
      const filtros = {
        filters: [{
          relacion: "and",
          columna: "fideicomiso_id",
          operador: "equal_to",
          valor1: this.fideicomiso_id,
          valor2: null
        }]
      }
      const dataSource = await beneficiariosApi.index(false, filtros)
      this.beneficiarios = dataSource.data
    },

    editar(index) {
      this.index_seleccionado = index
      this.formBeneficiario = true
      this.es_fondeador = !!this.beneficiario.tipo && this.beneficiario.tipo.toLowerCase()  === 'fondeador'
    },

    cerrar_modal_formulario() {
      this.index_seleccionado = null
      this.formBeneficiario = false
    }

    , cancelar_seleccion() {
      this.formBeneficiario = false;
      this.openModalEliminarCesionario = false;

      this.index_seleccionado = null
    }

    , cancelar_eliminiar() {
      this.index_seleccionado = null
      this.openModalEliminarCesionario = false
    }
    , pre_eliminar(index) {
      this.index_seleccionado = index
      this.openModalEliminarCesionario = true
    }

    , async eliminar() {

      if (this.beneficiario.hasOwnProperty('id') && this.beneficiario.id !== null)
        await beneficiariosApi.eliminar(this.fideicomiso_id, this.beneficiario.id)

      this.beneficiarios.splice(this.index_seleccionado, 1)

      this.index_seleccionado = null
      this.openModalEliminarCesionario = false
      this.$helper.showMessage('Eliminar', 'Información eliminada exitosamente.', 'success', 'alert')
    }

    , async guardar() {

      let isValid = await this.$validator({
        external_id: !!this.beneficiario.external_id ?  'max:150' : '',
        nombre: !this.beneficiario.nombre ? 'required' : 'max:150',
        tipo: !this.beneficiario.tipo ? 'required' : '',
        porcentaje_beneficio: !this.beneficiario.porcentaje_beneficio ? 'required' : '',
        beneficiario_id: !this.beneficiario.beneficiario_id && this.es_fondeador ? 'required' : '',
        beneficiario_estatus: !this.beneficiario.estatus ? 'required' : '',
      })


      if (isValid) {
        try {
          if (this.beneficiario.id !== null) {
            const request = await beneficiariosApi.editar(this.fideicomiso_id, this.beneficiario.id, this.beneficiario)
            this.$helper.showMessage('Editar beneficiario', 'Información guardada exitosamente.', 'success', 'alert')

          } else {
            const request = await beneficiariosApi.crear(this.fideicomiso_id, this.beneficiario)
            this.$helper.showMessage('Crear beneficiario', 'Información guardada exitosamente.', 'success', 'alert')
          }
          this.cancelar_seleccion()
          await this.cargarListado()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage((!this.cliente_id ? 'Guardar' : 'Editar') + ' beneficiario', 'Ocurrio un error al guardar datos del beneficiario, favor de reportarlo al administador.', 'error', 'alert')
        }
      }

    }
  }
  ,watch: {
    'beneficiario.tipo': {
      handler() {
        if (this.es_fondeador && !this.fondeadores.length)
          this.cambioTipo()
      }
    }
  }
}
</script>

<style scoped lang="scss">
#container-listado{
  max-height: 400px;
  overflow-y: scroll;

  table th:first-child{
    width: 110px;
  }
}
</style>
