
<template>

  <div>
    <Modal  :options="{width: '90vw', close:true}" @close="$emit('cancelar')">
      <div class="title h2 text-white">Detalle del cliente</div>

      <div class="container-fluid mb-5">

        <div class="row">

          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row text-center">
                  <div class="col col-md-3 col-sm-12 mt-2 pr-5 border-right">
                    <div class="rounded-circle bg-secondary d-flex m-auto" style="width: 85px; height: 85px"> <span class="h2 text-uppercase m-auto text-white">{{avatar}}</span> </div>

                    <div class="mt-2 mb-3">
                      <b>Nombre: </b> {{cliente.nombre+' '+cliente.apellido_paterno+' '+cliente.apellido_materno}} <br>
                      <b>Identificador: </b> {{!cliente.identificador_externo ? '---' : cliente.identificador_externo}} <br>
                    </div>


                    <button type="button" class="btn btn-primary mt-2" @click="openFormularioCliente = true" id="btn-editar-cliente" name="btn-editar-cliente"><i class='fa fa-edit mr-sm-2'></i>Editar cliente</button>
                  </div>

                  <div class="col col-md-9 col-sm-12">
                    <h5 class="card-title">Información del cliente</h5>
                    <div class="row">
                      <div class="col-sm-3 form-group">
                        <b class="text-secondary">Persona Jurídica:</b> <br>
                        <b>{{cliente.persona_juridica}}</b>
                      </div>

                      <div class="col-sm-3 form-group" v-for="campo in campos_dinamicos ">
                        <b class="text-secondary">{{ campo.nombre }}:</b> <br>
                        <b v-if="cliente[campo.clave] == null">Sin información</b>
                        <b v-else>{{cliente[campo.clave]}}</b>
                      </div>
                    </div>

                    <hr>

                    <div class="col-12">
                      <h5 class="card-title">Datos de contacto</h5>

                      <div class="text-right mb-4">
                        <button class="btn btn-sm btn-success" @click="openFormularioContacto = true"> <i class='fa fa-plus mr-sm-1'></i>Agregar dato de  contacto</button>
                      </div>

                      <div class="row">
                        <div v-if="!cliente.contactos.length" class="col-12 text-secondary text-center"> No se encontraron datos de contacto para mostrar.</div>
                        <div class="col-md-auto col-sm border mr-sm-2 p-sm-2 mb-2"  v-for="(contacto, index) in cliente.contactos" v-if="cliente.contactos">
                          <i class="fa fa-envelope text-info mr-sm-2" v-if="contacto.tipo_contacto == 'correo'"></i>
                          <i class="fa fa-phone text-primary mr-sm-2" v-if="contacto.tipo_contacto == 'telefono'"></i>
                          {{ contacto.valor }} <small class="text-secondary">({{contacto.tipo.toUpperCase()}})</small>
                          <button type="button" class="ml-3 btn btn-sm btn-primary" @click="editar_contacto(index)"><i class="fa fa-edit"></i></button>
                          <button class="btn btn-sm btn-dark ml-2" @click="pre_eliminar_contacto(index)"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>


                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div class="card mt-5">
          <div class="card-body">
            <ul class="nav nav-pills nav-fill text-center mb-1" style="border-bottom: solid 1px #e0e0e0">
              <li class="nav-item">
                <a class="nav-link rounded-0" :class="(tab_activa === 'tab-garantias') && 'active'" aria-current="page" href="#" @click="change_tab('tab-garantias')"><i class="fa fa-home mr-sm-2"></i> Domicilios </a>
              </li>
              <li class="nav-item">
                <a class="nav-link rounded-0" :class="(tab_activa === 'tab-creditos') && 'active'" aria-current="page" href="#" @click="change_tab('tab-creditos')"><i class="fa fa-credit-card mr-sm-2"></i> Créditos </a>
              </li>
              <li class="nav-item">
                <a class="nav-link rounded-0" :class="(tab_activa === 'tab-actividad') && 'active'" aria-current="page" href="#" @click="change_tab('tab-actividad')"><i class="fa fa-history mr-sm-2"></i> Actividad </a>
              </li>
            </ul>

            <div class="tab-content" id="nav-tabContent">
              <div class="container tab-pane fade mt-4 pt-4" :class="(tab_activa === 'tab-garantias') && 'show active'" v-if="tab_activa === 'tab-garantias'">
                <domiclios-cliente :persona_id="cliente_id"></domiclios-cliente>
              </div>

              <div class="container tab-pane fade  mt-4 pt-4" :class="(tab_activa === 'tab-creditos') && 'show active'" v-if="tab_activa === 'tab-creditos'">
                <creditos-cliente :persona_id="cliente_id"></creditos-cliente>
              </div>
              <div class="container tab-pane fade  mt-4 pt-4" :class="(tab_activa === 'tab-actividad') && 'show active'" v-if="tab_activa === 'tab-actividad'">
               <Log v-if="logs_persona" :registros_por_pagina="logs_persona.limit" :logs_list="logs_persona.data"  :total="logs_persona.total" @cargar_pagina="cargar_log($event)"></Log>
              </div>
            </div>
          </div>
        </div>

      </div>


    </Modal>
    <formulario-cliente v-if="openFormularioCliente" @cliente_guardado="cargar_data_cliente()" :input_cliente="cliente" :cliente_id="cliente_id" :campos_dinamicos="campos_dinamicos" @cancelar="openFormularioCliente = false" ></formulario-cliente>

    <formulario-contacto v-if="openFormularioContacto" @actualizar="cargar_data_cliente()" :persona="cliente_id" :input_contacto="contacto_seleccionado" @cancelar="cancelar_formularios_contacto" ></formulario-contacto>

    <Modal v-if="openContactoEliminar" :options="{width: '30vw',type:'error'}" @close="openContactoEliminar = false">
      <div class="title">Eliminar {{ label_contacto }} de contacto</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el contacto:  {{ contacto_seleccionado.valor }}
            <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_contacto"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_formularios_contacto"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

  </div>



</template>

<script>
import personasApi from "@/apps/garantias_app/api/personas/personas.api";
import CampoDinamico from "@/apps/garantias_app/components/CampoDinamico.vue";
import Modal from "@/components/Modal.vue";
import FormularioCredito from "@/apps/garantias_app/pages/clientes/creditos/FormularioCredito.vue";
import CreditosCliente from "./CreditosCliente.vue";
import FormularioCliente from "@/apps/garantias_app/pages/clientes/FormularioCliente.vue";
import FormularioContacto from "@/apps/garantias_app/pages/clientes/FormularioContacto.vue";
import DomicliosCliente from "@/apps/garantias_app/pages/clientes/DomiciliosCliente.vue";
import contactosApi from "@/apps/garantias_app/api/personas/contactos.api";
import Log from "@/apps/garantias_app/components/Log.vue";

export default {
  name: "Cliente"
  ,props:{cliente_id:null}
  ,components: {
    Log,
    DomicliosCliente,
    FormularioContacto,
    FormularioCliente,
    CreditosCliente,
    FormularioCredito,
    Modal,
    CampoDinamico
  }
  ,data(){
    return {
      cliente:{
        tipo: 'cliente' // Campo necesario para definir el tipo de persona que se da de alta en la bd
        ,nombre:null
        ,apellido_paterno:null
        ,apellido_materno:null
        ,correo:null
        ,persona_juridica:null
        ,identificador_externo: null
        ,domicilios: []
        ,contactos: []
        ,propiedades: [] // campos dinamicos del cliente
      }
      ,campos_dinamicos: []
      ,openFormularioCliente: false
      ,validacion_domicilios: false
      ,pasos: [
        {
          texto: "Información del cliente"
          ,valor: "informacion-cliente"
        },
        {
          texto: "Información del crédito"
          ,valor: "informacion-credito"
        }
      ]
      ,tab_activa: 'tab-garantias'
      ,openFormularioContacto: false
      ,openContactoEliminar: false
      ,contacto_seleccionado: {}
      ,index_contacto_eliminar:  null
      ,limit_logs: 5
      ,logs_persona: {
        data: [],
        limit: 10,
        total: 0
      }
    }
  }
  ,mounted() {
    this.cargar_data_cliente()

  }
  ,computed:{
    label_contacto(){
      const tipo = this.contacto_seleccionado.tipo_contacto;
      const tipos_contactos_labels =  {  telefono:"Teléfono" , correo: "Correo" }
      return tipos_contactos_labels.hasOwnProperty(tipo) ? tipos_contactos_labels[tipo] : tipo.camelCase;
    },
    editar_cliente(){
      return (typeof this.cliente != "undefined" && typeof this.cliente != null)
    },
    avatar(){
      return (!this.cliente.nombre) ? '':(this.cliente.nombre[0]+this.cliente.apellido_paterno[0]);
    }
  }
  ,methods:{
    change_tab(tab){
      this.tab_activa = tab

      if (tab === 'tab-actividad'){
        this.cargar_log();
      }
    },

   async cargar_log(index = null){

      const usuario = this.$auth.getAuth();
      let options = {
        page: !index ? 1 : index,
        order_col: 'id',
        order_dir: 'desc',
        filters: [
            {columna:'usuario_id', operador:'equal_to',  relacion:'and', valor1:usuario.usuario.id, valor2:null},
        ],
        limit: this.logs_persona.limit,
      }

     const response = await personasApi.logs(this.cliente_id,options)
     this.logs_persona.data = response.data.data
     this.logs_persona.total = response.data.total




    },

    pre_eliminar_contacto(index){

      if (this.cliente.contactos.length > 1){
        this.contacto_seleccionado = {...this.cliente.contactos[index]}
        this.index_contacto_eliminar = index
        this.openContactoEliminar = true
      }else{
        this.$helper.showMessage('Eliminar contacto', 'No se puede eliminar el contacto, el cliente debe tener al menos uno agregado.', 'error', 'alert')
      }


    },

    async eliminar_contacto(){

      if (this.contacto_seleccionado.hasOwnProperty('id') && this.contacto_seleccionado.id != null)
        await contactosApi.eliminar(this.cliente_id, this.contacto_seleccionado.id)

      this.cliente.contactos.splice(this.index_contacto_eliminar,1)

      this.cancelar_formularios_contacto()

      this.$helper.showMessage('Eliminar contacto', 'Contacto eliminada exitosamente.', 'success', 'alert')
    },

    editar_contacto(index){
      this.contacto_seleccionado = {...this.cliente.contactos[index]}
      this.openFormularioContacto = true
    },

    cancelar_formularios_contacto(){

      this.index_contacto_eliminar = null
      this.contacto_seleccionado = {}

      this.openFormularioContacto = false
      this.openContactoEliminar = false

      this.$emit('actualizar')


    },

    async cargar_data_cliente(){

      // Se cierran los modales abiertos
      this.cancelar_formularios_contacto()
      this.openFormularioCliente = false

      if (this.editar_cliente) {
        const dataSource = await personasApi.obtener(this.cliente_id)
        this.cliente = dataSource.data
        if (this.cliente.external_id)
          this.cliente.identificador_externo = this.cliente.external_id
      }
      await this.cargarCampos()
    }

  ,async cargarCampos(){
      let dataSource = await personasApi.campos_dinamicos()
      this.campos_dinamicos = dataSource.data

      for (let index = 0 ; index < this.campos_dinamicos.length; index++) {

        const campo = this.campos_dinamicos[index]

        /**
         * Si no esta editando un cliente se descarta mostrar campos dinámicos que no esten activos
         * */
        if (!this.editar_cliente){
          if (!campo.activo) {
            this.campos_dinamicos.splice(index,1)
          }else
            this.cliente[campo.clave] = null

          continue
        }

        let propiedad = {}

        /**
         * Validar si se esta editando un registro y buscar en sus propiedades los valores de los campos dinamicos
         * las propiedades se obtienen al consultar el registro en el api y se asignan sus valores
         * */
        if(this.cliente.propiedades){
          propiedad = this.cliente.propiedades.find(propiedad => propiedad.campo_id === campo.id)

          /**
           * Se omiten los campos dinámicos que esten inactivos si la propiedad para el mismo no existe en
           * las propiedades del registro en edición
           */
          if (!campo.activo && !propiedad) {
            this.campos_dinamicos.splice(index--,1)
            continue
          }
        }

        this.cliente[campo.clave] = propiedad && propiedad.hasOwnProperty('valor')  ? propiedad.valor : null
      }

    }
  }
}
</script>

<style scoped lang="scss">
.btn-acciones:hover{
  cursor: pointer;
}
</style>
