
<template>

  <div>

    <Modal  :options="{width: '95vw'}">
      <div class="title h2 text-white">{{ !cliente_id ? 'Agregar Cliente' : 'Editar Cliente' }}</div>

      <div class="body">
        <div class="text-center my-4">
          <div class="h5">Información del Cliente</div>
        </div>

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan los iconos:
          <ul class="list-group border-0 mt-2">
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-external-link mx-sm-1"></i> indican que fueron cargados desde un origen externo, si edita los valores obtenidos ya no podrán recuperarse.</li>
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-refresh mx-sm-1"></i> hacen referencia a campos dinámicos configurados por el administrador y pueden cambiar en cualquier momento.</li>
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-check-circle mx-sm-1"></i> indica que el campo es obligatorio.</li>
          </ul>
        </div>

        <div class="container-fluid">
          <div class="row">


            <div class="col-sm-3 form-group">
              <label for="identificador_externo" class="form-label text-truncate d-block" title="Identificador externo">
                Identificador externo
              </label>
              <input v-model="cliente.identificador_externo" type="text" name="identificador_externo" id="identificador_externo" class="form-control">
            </div>


            <div class="col-sm-3 form-group">
              <label for="nombre" class="form-label text-truncate d-block" title="Nombre"><i class="fa fa-check-circle text-primary"></i> Nombre</label>
              <input v-model="cliente.nombre" type="text" name="nombre" id="nombre" class="form-control">
            </div>

            <div class="col-sm-3 form-group">
              <label for="apellido_paterno" class="form-label text-truncate d-block" title="Apellido paterno">
                Apellido paterno
              </label>
              <input v-model="cliente.apellido_paterno" type="text" name="apellido_paterno" id="apellido_paterno" class="form-control">
            </div>

            <div class="col-sm-3 form-group">
              <label for="apellido_materno" class="form-label text-truncate d-block" title="Apellido materno">
                Apellido materno
              </label>
              <input v-model="cliente.apellido_materno" type="text" name="apellido_materno" id="apellido_materno" class="form-control" >
            </div>

            <div class="col-sm-3 form-group" v-if="!cliente_id">
              <label for="correo" class="form-label text-truncate d-block" title="Correo electrónico">
                <i class="fa fa-check-circle text-primary"></i>
                Correo electrónico
              </label>
              <input v-model="cliente.correo" type="email" name="correo" id="correo" class="form-control">
            </div>
            <div class="col-sm-3 form-group">
              <label for="persona_juridica" class="form-label text-truncate d-block" title="Persona Jurídica">
                <i class="fa fa-check-circle text-primary"></i>
                Persona Jurídica
              </label>
              <select class="form-control" name="persona_juridica" id="persona_juridica"  v-model="cliente.persona_juridica" >
                <option :value="tipo_persona" v-for="tipo_persona in tipos_personas">{{tipo_persona}}</option>
              </select>
            </div>

            <div class="col-sm-3 form-group" v-for="campo in campos_dinamicos ">

              <campo-dinamico v-if="campo.catalogo_id"
                              :label="campo.nombre"
                              :clave="campo.clave"
                              :tipo="campo.tipo_dato"
                              :valor="cliente[campo.clave]"
                              :opciones_select="campo.catalogo.opciones"
                              :select_clave_valor="true"
                              :select_opciones_label="'nombre'"
                              :select_opciones_valor="'valor'"
                              v-model="cliente[campo.clave]"
              >
                <template v-slot:label>
                  <button  v-if="campo.es_de_origen_externo" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
                </template>

              </campo-dinamico>

              <campo-dinamico v-else :label="campo.nombre"
                              :clave="campo.clave"
                              :tipo="campo.tipo_dato"
                              :valor="cliente[campo.clave]"
                              :opciones_select="campo.tipo_dato !== 'array' ?  [] : campo.valores"
                              v-model="cliente[campo.clave]"
              >
                <template v-slot:label>
                  <button  v-if="campo.es_de_origen_externo" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
                </template>

              </campo-dinamico>
            </div>

          </div>

        </div>


      </div>


      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="$emit('cancelar')"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          <div class="col-auto"><button type="button" class="btn btn-success" @click="guardar_cliente" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>Guardar
            {{ !cliente_id ? '' : 'cambios' }}</button></div>
        </div>
      </div>


    </Modal>


  </div>


</template>

<script>
import personasApi from "@/apps/garantias_app/api/personas/personas.api";
import CampoDinamico from "@/apps/garantias_app/components/CampoDinamico.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "FormularioCliente"
  ,props:{
    cliente_id: {
      type: null | String
      ,required: true
      ,default() {
        return null
      }
    },
    input_cliente: { //Si se pasa input_cliente debe tener todas las propiedades que se listan en los valores por defecto para evitar errores de propiedades 'undefined'
      type: null | String
      ,required: false
      ,default() {
        return {
          id: null
          ,tipo: 'cliente' // Campo necesario para definir el tipo de persona que se da de alta en la bd
          ,nombre:null
          ,apellido_paterno:null
          ,apellido_materno:null
          ,correo:null
          ,persona_juridica:null
          ,identificador_externo: null
          ,propiedades: [] // campos dinamicos del cliente
        }
      }
    },

  }
  ,components: {Modal, CampoDinamico}
  ,data(){
    return {
      cliente: {
        tipo: 'cliente' // Campo necesario para definir el tipo de persona que se da de alta en la bd (en este caso es un cliente por eso el valor por defecto)
        ,nombre:null
        ,apellido_paterno:null
        ,apellido_materno:null
        ,correo:null
        ,persona_juridica:null
        ,identificador_externo: null
        ,propiedades: [] // campos dinamicos del cliente
      }
      ,campos_dinamicos: []
      ,pasos: [
        {
          texto: "Información del cliente"
          ,valor: "informacion-cliente"
        },
        {
          texto: "Información del crédito"
          ,valor: "informacion-credito"
        }
      ]
      ,tipos_personas: ["Persona Física","Persona Física con Actividad Empresarial","Persona Moral"]
    }
  }
  ,mounted() {
    this.cargar_data_cliente()
  }
  ,computed:{
    input_cliente_id(){
      return this.input_cliente.hasOwnProperty('id') ? this.input_cliente.id : null
    },
    validaciones_cliente(){

      let validaciones = {
        nombre: !this.cliente.nombre ? 'required' : 'max:50',
        apellido_paterno: !this.cliente.apellido_paterno ? '' : 'max:50',
        apellido_materno: !this.cliente.apellido_materno ? '' : 'max:50',
        persona_juridica: !this.cliente.persona_juridica ? 'required' : '',
        correo: !this.cliente.correo ? 'required' : 'max:100',
        identificador_externo: !!this.cliente.identificador_externo ? 'max:255' : '',
      }

      for (const campo of this.campos_dinamicos) {
        validaciones[campo.clave] = !this.cliente[campo.clave] && campo.campo_requerido  ? 'required' : (campo.tipo_dato === 'string' ? 'max:255' : '')
      }

      return validaciones;
    }
  }
  ,methods:{
    async cargar_data_cliente(){

      if (typeof this.input_cliente === 'object' && typeof this.input_cliente_id === 'string')
        this.cliente = {...this.input_cliente}
      else {
        if (typeof this.cliente_id == "string") {
          const dataSource = await personasApi.obtener(this.cliente_id)
          this.cliente = dataSource.data
        }

      }
      await this.cargarCampos()

      this.cliente.identificador_externo = !this.cliente.external_id ? null : this.cliente.external_id
    }

    ,async cargarCampos(){

      let dataSource = await personasApi.campos_dinamicos()
      this.campos_dinamicos = dataSource.data

      for (let index = 0 ; index < this.campos_dinamicos.length; index++) {

        const campo = this.campos_dinamicos[index]

        /**
         * Si no esta editando un cliente se descartan los campos dinámicos que no esten activos para
         * el nuevo registro
         */
        if (!this.cliente_id){
          if (!campo.activo) {
            this.campos_dinamicos.splice(index,1)
          }else
            this.cliente[campo.clave] = null

          continue
        }

        let propiedad = {}

        /**
         * Validar si se esta editando un registro y buscar en sus propiedades los valores de los campos dinamicos
         * las propiedades se obtienen al consultar el registro en el api y se asignan sus valores
         * */
        if(this.cliente.propiedades){
          propiedad = this.cliente.propiedades.find(propiedad => propiedad.campo_id === campo.id)

          /**
           * Se omiten los campos dinámicos que esten inactivos si la propiedad para el mismo no existe en
           * las propiedades del registro en edición
           */
          if (!campo.activo && !propiedad) {
            this.campos_dinamicos.splice(index--,1)
            continue
          }
        }


        this.cliente[campo.clave] = propiedad && propiedad.hasOwnProperty('valor')  ? propiedad.valor : null
      }

    }
    ,async guardar_cliente(){
      let isValid = await this.$validator(this.validaciones_cliente)

      if (isValid) {
        try {
          if (this.cliente_id !== null) {
            const request = await personasApi.editar(this.cliente_id,  this.cliente)
            this.$helper.showMessage('Editar cliente', 'Información del cliente guardada exitosamente.', 'success', 'alert')
            this.$emit('cliente_guardado', request.data)


          }else{
            const request = await personasApi.crear(this.cliente)
            this.cliente_id = request.data.id
            this.$helper.showMessage('Crear cliente', 'Información del cliente guardada exitosamente.', 'success', 'alert')
            this.$emit('cliente_guardado', request.data)
          }



        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( (!this.cliente_id ? 'Guardar' : 'Editar')+ ' cliente', 'Ocurrio un error al guardar datos del cliente, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    }

  }
}
</script>

<style scoped lang="scss">

</style>
