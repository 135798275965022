<template>
  <div class="w-100">

    <div class="d-flex justify-content-end">
        <button class="btn btn-sm btn-primary mr-4" @click="cargar_pagina(paginaActual)"> <i class='fa fa-refresh mr-sm-1'></i>Refrescar</button>
        <ul class="pagination mb-0">
          <li class="page-item" :class="(paginaActual === 1) && 'disabled'">
            <a class="page-link"  @click="$emit('cargar_pagina', paginaActual-1)">Anterior</a>
          </li>
          <li v-for="index in total_paginas" :class="'page-item'+(index === paginaActual ? ' active' : '')" @click="cargar_pagina(index)"><span class="page-link">{{ index }}</span></li>

          <li class="page-item"  :class="(paginaActual === total_paginas) && 'disabled'" >
            <a class="page-link" @click="cargar_pagina( paginaActual+1)">Siguiente</a>
          </li>
        </ul>


    </div>


    <div class="listado-historial d-flex flex-column pl-3 pt-3">
      <div class="d-flex mb-1" v-for="item in logs_list">
        <div class="d-flex flex-column pr-4 align-items-center">
          <div class="rounded-circle py-2 px-2 bg-primary text-white mb-1  item-log-point"></div>
          <div class="line h-100"></div>
        </div>

        <div class="row no-gutters w-100">
          <div class="col-md-4 col-sm-1">
            <small class="text-muted">{{ $moment(item.created_at).format('LLLL')  }}</small>
          </div>
          <div class="col-md-8 col-sm-12">
            <p>
              <b>{{item.usuario_nombre}} - </b> {{item.descripcion}}: <span class="text-secondary">{{descripcion_cambios(item)}} </span>
            </p>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: 'Log',
  props: {
    logs_list: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },

    registros_por_pagina:{
      type: Number,
      required: false,
      default() {
        return 20;
      }
    }
    ,total:{
      type: Number,
      required: false,
      default() {
        return 10;
      }
    }
  }
  ,data(){
    return {
      paginaActual: 1
    }
  },
  computed:{
    total_paginas: function() {
      return Math.ceil(this.total / this.registros_por_pagina);
    }
  },
  mounted() {
  },
  methods:{
    cargar_pagina(pagina){
      this.$emit('cargar_pagina', pagina)
      this.paginaActual = pagina
    },
    descripcion_cambios(item_log){

      return item_log.accion === 'editar' ?  item_log.valores_actuales.replaceAll("'",'')
          .replaceAll('"','')
          .replaceAll("_",' ')
          .replaceAll("{",'')
          .replaceAll("}",'')
          .replaceAll("[",'')
          .replaceAll("]",'')
          .replaceAll(",",', ') : ''
    }
  }
}
</script>

<style scoped>
.listado-historial {
  max-height: 400px;
  overflow-y: scroll;
  .line {
    width: 2px;
    background-color: lightgrey !important;
  }
  .item-log-point {
    box-shadow: 1px 1px 8px 9px #bdcde4;
  }
}

</style>
