import garantiasAdminApi from "../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  crear(persona,payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/personas/${persona}/contactos`, payload)
  },

  editar(persona,contacto, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/personas/${persona}/contactos/${contacto}`, payload)
  },

  eliminar(persona, contacto) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/personas/${persona}/contactos/${contacto}`)
  },

}