
<template>

  <div>

    <div class="container mb-5">
      <div class="text-right">
        <button class="btn btn-sm btn-success" @click="agregarDomicilio"> <i class='fa fa-plus mr-sm-1'></i>Agregar domicilio</button>
      </div>
    </div>

    <div id="container-listado-domicilios">
      <table class="table table-sm table-striped">
        <thead>
        <tr class="bg-dark">
          <th scope="col">
          </th>
          <th scope="col">Calle</th>
          <th scope="col">Número exterior</th>
          <th scope="col">Número interior</th>
          <th scope="col">Colonia</th>
          <th scope="col">CP</th>
          <th scope="col">Municipio</th>
          <th scope="col">Estado</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!domicilios.length"><td colspan="9" class="text-secondary text-center"> No se encontraron domicilios para mostrar.</td></tr>
        <tr v-for="(domicilio, index) in domicilios">
          <td>
            <button type="button" class="ml-3 btn btn-sm btn-primary" @click="editar_domicilio(index)"><i class="fa fa-edit"></i></button>
            <button class="btn btn-sm btn-dark ml-2" @click="pre_eliminar_domicilio(index)"><i class="fa fa-trash"></i></button>
          </td>
          <td>{{ domicilio.calle }}</td>
          <td>{{domicilio.numero_exterior}}</td>
          <td>{{(!domicilio.numero_interior ? '--' : domicilio.numero_interior)}}</td>
          <td>{{ domicilio.colonia }}</td>
          <td>{{ domicilio.cp }}</td>
          <td>{{ domicilio.municipio }}</td>
          <td>{{ domicilio.estado }} </td>
        </tr>
        </tbody>
      </table>
    </div>



    <Modal v-if="openModalEliminarDomicilio" :options="{width: '30vw',type:'error'}" @close="openModalEliminarDomicilio = false">
      <div class="title">Eliminar dirección</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás la dirección  {{ domicilio.calle+' '+domicilio.numero_exterior+' '+(!domicilio.numero_interior ? '' : domicilio.numero_interior) }}
            <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_domicilio"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_eliminiar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <formulario-domicilio v-if="formDomicilio" :domicilio_prop="domicilio" :persona_id="persona_id" @guardar="agregar_domicilio($event)" @cancelar="cerrar_modal_domicilio"></formulario-domicilio>

    </div>

</template>

<script>
import domiciliosApi from "@/apps/garantias_app/api/personas/domicilios.api";
import CampoDinamico from "@/apps/garantias_app/components/CampoDinamico.vue";
import Modal from "@/components/Modal.vue";
import FormularioDomicilio from "@/apps/garantias_app/pages/clientes/domicilios/FormularioDomicilio.vue";

export default {
  name: "DomicliosCliente"
  ,props:{persona_id: null}
  ,components: {FormularioDomicilio, Modal, CampoDinamico}
  ,data() {
    return {
       domicilios: []
      , formDomicilio: false
      , index_domicilio_seleccionada: null
      , openModalEliminarDomicilio: false
    }
  }
  ,mounted() {

    this.cargarDomicilios()

  }
  ,computed:{
    domicilio(){
      return this.index_domicilio_seleccionada == null ? {} : {...this.domicilios[this.index_domicilio_seleccionada]}
    }

  }
  ,methods:{

    agregarDomicilio(){
      this.formDomicilio = true
    },

    async cargarDomicilios(){
      const dataSource = await domiciliosApi.index(this.persona_id)
      this.domicilios = dataSource.data
    },

    editar_domicilio(index){
      this.index_domicilio_seleccionada = index
      this.formDomicilio = true
    },

    cerrar_modal_domicilio(){
      this.index_domicilio_seleccionada = null
      this.formDomicilio = false
    }

    ,cancelar_eliminiar(){
      this.index_domicilio_seleccionada = null
      this.openModalEliminarDomicilio = false
    }
    ,pre_eliminar_domicilio(index){
      if (this.domicilios.length > 1) {
        this.index_domicilio_seleccionada = index
        this.openModalEliminarDomicilio = true
      }else{
        this.$helper.showMessage('Eliminar dirección', 'No se puede eliminar la dirección, el cliente debe tener al menos una agregada.', 'error', 'alert')
      }
    }

    ,async eliminar_domicilio(){

      if (this.domicilio.hasOwnProperty('id') && this.domicilio.id !== null)
        await domiciliosApi.eliminar(this.persona_id, this.domicilio.id)

      this.domicilios.splice(this.index_domicilio_seleccionada,1)

      this.index_domicilio_seleccionada = null
      this.openModalEliminarDomicilio = false
      this.$helper.showMessage('Eliminar dirección', 'Domicilio eliminada exitosamente.', 'success', 'alert')
    }

    ,async agregar_domicilio(domicilio){

      //Validar si es un nuevo domicilio o edición
      if (this.index_domicilio_seleccionada == null) {
        this.domicilios.push(domicilio)
      }else {
        this.$set(this.domicilios, this.index_domicilio_seleccionada, domicilio)
        this.index_domicilio_seleccionada = null
      }

      this.formDomicilio = false
    }

  }
}
</script>

<style scoped lang="scss">
#container-listado-domicilios{
  max-height: 400px;
  overflow-y: scroll;

  table th:first-child{
    width: 110px;
  }
}
</style>
