
<template>

  <div>

    <div class="container mb-5">
      <div class="text-right">
        <button class="btn btn-sm btn-success" @click="agregar" :disabled="input_solo_lectura"> <i class='fa fa-plus mr-sm-1'></i>Agregar movimiento</button>
      </div>
    </div>

    <div id="container-listado">
      <table class="table table-sm table-striped">
        <thead>
        <tr class="bg-dark">
          <th scope="col">
          </th>
          <th scope="col">Nombre</th>
          <th scope="col" v-if="!movimientos_de_cesionario">Cesionario</th>
          <th scope="col">Tipo</th>
          <th scope="col">Monto</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!movimientos.length"><td colspan="9" class="text-secondary text-center"> No se encontraron movimientos para mostrar.</td></tr>
        <tr v-for="(item, index) in movimientos">
          <td>
            <button type="button" class="ml-3 btn btn-sm btn-primary" @click="editar(index)"><i :class="!input_solo_lectura ? 'fa fa-edit' : 'fa fa-eye'"></i></button>
            <button class="btn btn-sm btn-dark ml-2" @click="pre_eliminar(index)" v-if="!input_solo_lectura"><i class="fa fa-trash"></i></button>
          </td>
          <td>{{ item.nombre }}</td>
          <td v-if="!movimientos_de_cesionario">{{ item.cesionarios_movimientos.nombre }}</td>
          <td>{{ item.nombre_tipo }}</td>
          <td>${{utilidades.moneyFormat(item.monto)}}</td>
        </tr>
        </tbody>
      </table>
    </div>



    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminar movimiento</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el movimiento  {{ movimiento.nombre }}
            <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_eliminiar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="formMovimiento" :options="{width: '50vw',type:'info'}" @close="cancelar_seleccion">
      <div class="title">{{ !movimiento.id ? 'Agregar Movimiento' : 'Editar Movimiento'}}</div>
      <div class="body">

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b>
          <br>Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
          <br>El campo Cesionario listara todos los cesionarios que esten agregados en la sección de Cesionarios del fideicomiso.
        </div>

        <div class="row justify-content-center mt-4">

          <div class="col-12 form-group" v-if="!movimiento.id">
            <label for="cesionario" class="form-label text-truncate d-block" title="Cesionario">
              <i class="fa fa-check-circle text-primary"></i>
              Cesionario
            </label>
            <div v-if="input_solo_lectura" class="text-bold"><b>{{cesionario}}</b></div>
            <select v-else class="form-control" name="cesionario" id="cesionario"  v-model="cesionario_id">
              <option :value="itemCesionario.id" v-for="itemCesionario in cesionarios">{{itemCesionario.nombre}}</option>
            </select>
          </div>

          <div class="col-12 form-group">
            <label for="nombre" class="form-label text-truncate d-block" title="Nombre">
              <i class="fa fa-check-circle text-primary"></i>
              Nombre
            </label>
            <div v-if="input_solo_lectura" class="text-bold"><b>{{movimiento.nombre}}</b></div>
            <input v-else v-model="movimiento.nombre" type="text" name="nombre" id="nombre" class="form-control">
          </div>

          <div class="col-12 form-group">
            <label for="monto" class="form-label text-truncate d-block" title="Monto">
              <i class="fa fa-check-circle text-primary"></i>
              Monto
            </label>
            <div v-if="input_solo_lectura" class="text-bold"><b>{{movimiento.monto}}</b></div>
            <IC v-else v-model="movimiento.monto" label="$" id="monto" name="monto" :formats="['moneyFormat']"/>
          </div>

          <div class="col-12 form-group">
            <label for="tipo" class="form-label text-truncate d-block" :title="tipos_movimientos.nombre">
              <i class="fa fa-check-circle text-primary"></i>
              {{ tipos_movimientos.nombre }}
            </label>
            <div v-if="input_solo_lectura" class="text-bold"><b>{{tipo_movimiento}}</b></div>
            <select v-else class="form-control" name="tipo" id="tipo"  v-model="movimiento.tipo">
              <option :value="tipo.valor" v-for="tipo in tipos_movimientos.opciones">{{tipo.nombre}}</option>
            </select>
          </div>

        </div>

      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto" v-if="!input_solo_lectura"><button class="btn btn-primary" @click="guardar" ><i class="fa fa-check-square mr-sm-1"></i>{{ !movimiento.id ? 'Guardar' : 'Guardar Cambios'}}</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
    </div>

</template>

<script>
import Modal from "@/components/Modal.vue";
import IC from "@/components/Form/InputCompuesto.vue";
import cesionariosApi from "@/apps/garantias_app/api/personas/fideicomisos/cesionarios/cesionarios.api";
import utilidades from "../../../../helpers/utilidades";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";

export default {
  name: "HonorariosFiduciarios"
  , props: {
    fideicomiso_id: null
    ,prop_cesionario_id: {
      type: String,
      required: false,
      default: null
    }
    ,input_solo_lectura: {
      type: Boolean
      ,required: false
      ,default() {
        return false
      }
    }

  }
  , components: {IC, Modal}
  , data() {
    return {
      movimientos: []
      , formMovimiento: false
      , index_movimiento_seleccionado: null
      , openModalEliminar: false
      ,tipos_movimientos: {nombre: 'Tipo', opciones: []}
      ,cesionarios: []
      ,cesionario_id: null

    }
  }
  , mounted() {
    this.cargar_data()
  }


  , computed: {
    utilidades() {
      return utilidades
    },
    movimiento() {
      return this.index_movimiento_seleccionado == null ? {
        id: null
        , nombre: null
        , tipo: null //ingreso/egreso
        , monto: null
      } : {...this.movimientos[this.index_movimiento_seleccionado]}
    }

    ,movimientos_de_cesionario(){
      return (typeof this.prop_cesionario_id === 'string')
    }
    ,cesionario(){
      const item_cesionario = this.cesionarios.find(cesionario => cesionario.id = this.cesionario_id)
      return !item_cesionario ? 'Desconocido' : item_cesionario.nombre;
    }
    ,tipo_movimiento(){
      const item_movimiento = this.tipos_movimientos.opciones.find(movimiento => movimiento.valor = this.movimiento.tipo)
      return !item_movimiento ? this.movimiento.tipo : item_movimiento.nombre;
    }


  }
  , methods: {

    cargar_data(){

      if (this.movimientos_de_cesionario)
        this.cesionario_id = this.prop_cesionario_id

      if (!this.tipos_movimientos.opciones.length)
        this.cargarCatalogoEstatus();

      this.cargarListado()

      this.cargarCesionarios()
    },

    async cargarCatalogoEstatus(){
      const dataSource = await catalogosApi.buscar('tipos_movimientos_fideicomisos')
      this.tipos_movimientos = dataSource.data

    },

    async cargarCesionarios() {
      const filtros = {
        columns: 'id,nombre',
        filters: [
          {
            relacion: "and",
            columna: "fideicomiso_id",
            operador: "equal_to",
            valor1: this.fideicomiso_id,
            valor2: null
          },
          {
            relacion: "and",
            columna: "estatus",
            operador: "not_equal_to",
            valor1: 'inactivo',
            valor2: null
          }
        ]
      }
      const dataSource = await cesionariosApi.index( false, filtros)
      this.cesionarios = dataSource.data
    },

    agregar() {
      this.formMovimiento = true
    },

    async cargarListado() {
      const dataSource = await  ( this.movimientos_de_cesionario ?  cesionariosApi.indexMovimientos(this.fideicomiso_id, this.cesionario_id) :
                                                                    cesionariosApi.indexMovimientos(this.fideicomiso_id) )
      this.movimientos = dataSource.data
    },

    editar(index) {
      this.index_movimiento_seleccionado = index
      this.cesionario_id = this.movimiento.identificador_padre
      this.formMovimiento = true
    }

    , cancelar_seleccion() {
      this.formMovimiento = false;
      this.openModalEliminar = false;

      this.index_movimiento_seleccionado = null

      if (!this.movimientos_de_cesionario)
        this.cesionario_id = null
    }

    , cancelar_eliminiar() {
      this.index_movimiento_seleccionado = null
      this.openModalEliminar = false
    }
    , pre_eliminar(index) {
      this.index_movimiento_seleccionado = index
      this.cesionario_id = this.movimiento.identificador_padre
      this.openModalEliminar = true
    }

    , async eliminar() {

      if (this.movimiento.hasOwnProperty('id') && this.movimiento.id !== null)
        await cesionariosApi.eliminarMovimiento(this.fideicomiso_id, this.cesionario_id, this.movimiento.id)

      this.movimientos.splice(this.index_movimiento_seleccionado, 1)

      this.index_movimiento_seleccionado = null
      this.openModalEliminar = false
      this.$helper.showMessage('Eliminar', 'información eliminada exitosamente.', 'success', 'alert')
    }

    , async guardar() {

      let isValid = await this.$validator({
        nombre: !this.movimiento.nombre ? 'required' : 'max:150',
        tipo: !this.movimiento.tipo ? 'required' : '',
        monto: !this.movimiento.monto ? 'required' : '',
        cesionario: !this.cesionario_id ? 'required' : '',
      })


      if (isValid) {
        try {
          if (this.movimiento.id !== null) {
            const request = await cesionariosApi.editarMovimiento(this.fideicomiso_id, this.cesionario_id, this.movimiento.id, this.movimiento)
            this.$helper.showMessage('Editar movimiento', 'Información guardada exitosamente.', 'success', 'alert')

          } else {
            const request = await cesionariosApi.crearMovimiento(this.fideicomiso_id, this.cesionario_id, this.movimiento)
            this.$helper.showMessage('Crear movimiento', 'Información guardada exitosamente.', 'success', 'alert')
          }
          this.cancelar_seleccion()
          await this.cargarListado()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage((!this.cliente_id ? 'Guardar' : 'Editar') + ' movimiento', 'Ocurrio un error al guardar datos del movimiento, favor de reportarlo al administador.', 'error', 'alert')
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">
#container-listado{
  max-height: 400px;
  overflow-y: scroll;

  table th:first-child{
    width: 110px;
  }
}
</style>
