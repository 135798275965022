
<template>

  <div>

    <div class="container mb-5">
      <div class="text-right">
        <button class="btn btn-sm btn-success" @click="agregarCredito"> <i class='fa fa-plus mr-sm-1'></i>Agregar crédito</button></div>
    </div>

    <table class="table table-sm table-striped">
      <thead>
      <tr class="bg-dark">
        <th scope="col"></th>
        <th scope="col">No. Crédito</th>
        <th scope="col">Estatus</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!creditos.length"><td colspan="3" class="text-secondary text-center"> No se encontraron créditos para mostrar.</td></tr>
      <tr v-for="(credito, index) in creditos">
        <td class="text-center">
          <button type="button" class="btn btn-sm btn-primary fa fa-edit" @click="editar_credito(index)">
          </button>
          <!--<button type="button" class="btn btn-sm btn-dark  ml-2" @click="pre_eliminar_credito(index)" ><i class="fa fa-trash"></i></button>-->
        </td>
        <td>{{ credito.external_id }}</td>
        <td>{{credito.estatus_nombre}}</td>
      </tr>
      </tbody>
    </table>

    <Modal v-if="openModalEliminarCredito" :options="{width: '30vw',type:'error'}" @close="openModalEliminarCredito = false">
      <div class="title">Eliminar credito </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el credito  {{ credito.external_id }} <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-primary" @click="eliminar_credito"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
            <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_eliminar_editar_credito"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <formulario-credito v-if="formCredito" :persona_id="persona_id" :credito_id="credito.id" @actualizar="cargarCreditos" @cancelar="cerrar_modal_credito"></formulario-credito>

    </div>

</template>

<script>
import creditosApi from "@/apps/garantias_app/api/personas/creditos/creditos.api";
import CampoDinamico from "@/apps/garantias_app/components/CampoDinamico.vue";
import Modal from "@/components/Modal.vue";
import FormularioCredito from "@/apps/garantias_app/pages/clientes/creditos/FormularioCredito.vue";

export default {
  name: "CreditosCliente"
  ,props:{persona_id: null}
  ,components: {FormularioCredito, Modal, CampoDinamico}
  ,data() {
    return {
       creditos: []
      , formCredito: false
      , credito_seleccionado: {}
      , index_credito_seleccionado: null
      , openModalEliminarCredito: false
      , openFormularioCredito: true
    }
  }
  ,mounted() {

    this.cargarCreditos()

  }
  ,computed:{
    credito(){
      return this.index_credito_seleccionado === null ? {id:null} : {...this.creditos[this.index_credito_seleccionado]}
    }

  }
  ,methods:{

    agregarCredito(){
      this.formCredito = true
    },

    async cargarCreditos(){
      const dataSource = await creditosApi.index(this.persona_id)
      this.creditos = dataSource.data
    },

    editar_credito(index){
      this.index_credito_seleccionado = index
      this.formCredito = true
    },

    cerrar_modal_credito(){
      this.index_credito_seleccionado = null
      this.formCredito = false
    }

    ,cancelar_eliminar_editar_credito(){
      this.index_credito_seleccionado = null
      this.openModalEliminarCredito = false
      this.formCredito = false
    }
    ,pre_eliminar_credito(index){
      this.index_credito_seleccionado = index
      this.openModalEliminarCredito = true
    }

    ,async eliminar_credito(){

      if (this.credito.hasOwnProperty('id') && this.credito.id !== null)
        await creditosApi.eliminar(this.persona_id, this.creditos[this.index_credito_seleccionado].id)

      this.creditos.splice(this.index_credito_seleccionado, 1)
      this.index_credito_seleccionado = null
      this.openModalEliminarCredito = false

      this.$helper.showMessage('Eliminar credito', 'Crédito eliminada exitosamente.', 'success', 'alert')
    }

  }
}
</script>

<style scoped lang="scss">

</style>
