
<template>

  <div>

    <div class="container mb-4">
      <div class="text-right">
        <button class="btn btn-sm btn-success" @click="formularioGarantia()" :disabled="input_solo_lectura"> <i class='fa fa-plus mr-sm-1'></i>Agregar garantía</button>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-12" v-if="!garantias_fideicomiso.length">
        <div class="alert alert-warning text-center w-100" role="alert">
          No se encontraron garantías vinculadas al fideicomiso, puede agregarlas dando clic en el botón "Agregar garantia".
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4" v-for="garantia in garantias_fideicomiso">
        <div class="card m-1">
          <div class="card-body p-3">
            <div class="card-text clearfix mb-3">
              <span class="badge badge-secondary py-1 text-uppercase text-white float-left">{{ garantia.tipo_garantia_juridica }}</span>
              <small class="text-muted float-right">Id Garantia: {{ garantia.garantia_id.substring(0,8)}}</small>
            </div>

            <i class="fa fa-home text-primary mr-sm-2"></i>
            <p class="card-text d-inline">
              {{ garantia.garantia.calle+' #'+garantia.garantia.numero_exterior+' '+(!garantia.garantia.numero_interior ? ' ' : garantia.garantia.numero_interior)
            +', '+garantia.garantia.colonia+', C.P. '+garantia.garantia.cp+', ' +garantia.garantia.municipio+', '+
            garantia.garantia.estado+', '+garantia.garantia.pais+'.'
              }}</p>
            <p class="card-text text-right mt-2">
              <button type="button" class="btn btn-sm btn-primary py-0 mr-2" @click="editar(garantia)" :disabled="input_solo_lectura"><i class="fa fa-edit"></i> Editar</button>
              <button type="button" class="btn btn-sm btn-danger py-0" @click="pre_eliminar(garantia)" :disabled="input_solo_lectura"><i class="fa fa-close"></i> Quitar</button>
            </p>
          </div>
        </div>
      </div>
    </div>




    <Modal v-if="openModalQuitarGarantia" :options="{width: '30vw',type:'error'}" @close="openModalQuitarGarantia = false">
      <div class="title">Quitar relación de garantia con fideicomiso</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminaras la relación entre el fideicomiso y la grantía: {{ direccion_garantia_editar }}
            <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="formGarantia" :options="{width: '50vw',type:'info'}">
      <div class="title">{{ !garantias_juridicas.id ? 'Agregar Garantia' : 'Editar Garantia' }}</div>
      <div class="body">
        <div class="row justify-content-center mt-4">
          <div class="col-12 form-group">
            <label for="garantia_id" class="form-label font-weight-bold">Garantía</label>

            <div v-if="garantia_fideicomiso.id" class="font-italic">{{direccion_garantia_editar}}</div>

            <select v-else class="form-control" name="garantia_id" id="garantia_id"  v-model="garantia_fideicomiso.garantia_id" :disabled="!garantias_select.length">
              <option :value="null" v-if="!garantias_select.length" selected>No hay garantias disponibles para agregar.</option>
              <option :value="garantia.id" v-for="garantia in garantias_select">
                {{ garantia.calle+' #'+garantia.numero_exterior+' '+(!garantia.numero_exterior ? ' ' : garantia.numero_exterior)+', '+garantia.colonia+', C.P. '+garantia.cp+', ' +garantia.municipio+', '+garantia.estado+', '+garantia.pais+'.'}}</p>
              </option>
            </select>

          </div>
          <div class="col-12 form-group">
            <label for="tipo_garantia_juridica" class="form-label font-weight-bold">Tipo de garantía jurídica</label>
            <div class="text-bold" v-if="input_solo_lectura"><b>{{tipo.nombre}}</b></div>
            <select v-else class="form-control" name="tipo_garantia_juridica" id="tipo_garantia_juridica"  v-model="garantia_fideicomiso.tipo_garantia_juridica">
              <option :value="tipo.valor" v-for="tipo in garantias_juridicas.opciones">{{ tipo.nombre}}</option>
            </select>

          </div>

        </div>

      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar" v-if="!input_solo_lectura"><i class="fa fa-check-square mr-sm-1"></i>{{ !garantia_fideicomiso.id ? 'Guardar' : 'Guardar Cambios' }}</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
    </div>

</template>

<script>
import Modal from "@/components/Modal.vue";
import utilidades from "../../../../helpers/utilidades";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";
import garantiasApi from "@/apps/garantias_app/api/personas/creditos/garantias.api";
import garantiasFideicomisoApi from "@/apps/garantias_app/api/personas/fideicomisos/garantias/garantiasFideicomiso.api";

export default {
  name: "FideicomisoGarantias"
  , props: {
    cliente_id: {
      type: String
      ,required: true
      ,default() {
        return null
      }
    },
    credito_id: {
      type: String
      ,required: true
      ,default() {
        return null
      }
    },
    fideicomiso_id: {
      type: String
      ,required: true
      ,default() {
        return null
      }
    }
    ,input_solo_lectura: {
      type: Boolean
      ,required: false
      ,default() {
        return false
      }
    }
  }
  , components: { Modal}
  , data() {
    return {
      garantias_credito: [],
      garantias_fideicomiso: [],
      garantias_juridicas: {nombre: 'Tipo', opciones: []}
      ,formGarantia: false
      ,openModalQuitarGarantia: false
      ,garantia_select: null
      ,garantia_fideicomiso: {
        id: null,
        fideicomiso_id: null,
        garantia_id: null,
        tipo_garantia_juridica: null,
        garantia: {}
      }
    }
  }
  , async mounted() {

    this.garantia_fideicomiso.fideicomiso_id = this.fideicomiso_id

    await this.garantiasFideicomiso()


  }
  , computed: {
    utilidades() {
      return utilidades
    },
    garantias_select() {
      return this.garantias_credito.filter((garantia_credito) => {
        return !this.garantias_fideicomiso.some(garantia_fideicomiso => garantia_fideicomiso.garantia_id === garantia_credito.id)
      })
    }
    ,direccion_garantia_editar() {
      return this.garantia_fideicomiso.garantia.calle + ' #' + this.garantia_fideicomiso.garantia.numero_exterior
          + ' ' + (!this.garantia_fideicomiso.garantia.numero_interior ? ' ' : this.garantia_fideicomiso.garantia.numero_interior)
          + ', ' + this.garantia_fideicomiso.garantia.colonia + ', C.P. ' + this.garantia_fideicomiso.garantia.cp
          + ', ' + this.garantia_fideicomiso.garantia.municipio + ', ' + this.garantia_fideicomiso.garantia.estado
          + ', ' + this.garantia_fideicomiso.garantia.pais + '.'

    }
  }
  , methods: {
    async garantiasFideicomiso() {

      const opciones = {
        filters: [{
          relacion: "and",
          columna: "fideicomiso_id",
          operador: "equal_to",
          valor1: this.fideicomiso_id,
          valor2: null
        }],
        relations: ['garantia']
      }

      const dataSource = await garantiasFideicomisoApi.index(false,opciones)
      this.garantias_fideicomiso = dataSource.data
    },

    async garantiasCredito() {

      const options = {
        filters: [{
          relacion: "and",
          columna: "estatus",
          operador: "not_equal_to",
          valor1: 'inactivo',
          valor2: null
        }]
      }
      const dataSource = await garantiasApi.index(this.cliente_id,this.credito_id, options)
      this.garantias_credito = dataSource.data

    },

    async formularioGarantia() {

      if (!this.garantias_credito.length) {
        await this.garantiasCredito()
      }

      if (!this.garantias_juridicas.opciones.length) {
        const dataSource = await catalogosApi.buscar('garantias_juridicas')
        this.garantias_juridicas = dataSource.data
      }

      this.formGarantia = true
    },


    editar(garantia) {
      this.garantia_fideicomiso = {...garantia}
      this.formularioGarantia()
    }
    , cancelar_seleccion() {
      this.formGarantia = false;
      this.openModalQuitarGarantia = false;

      this.garantia_fideicomiso =  {
        id: null,
        fideicomiso_id: this.fideicomiso_id,
        garantia_id: null,
        tipo_garantia_juridica: null,
        garantia: {}
      }
    }
    , pre_eliminar(garantia) {
      this.garantia_fideicomiso = {...garantia}
      this.openModalQuitarGarantia = true
    }

    , async eliminar() {

      if (this.garantia_fideicomiso.hasOwnProperty('id') && this.garantia_fideicomiso.id !== null)
        await garantiasFideicomisoApi.eliminar(this.fideicomiso_id, this.garantia_fideicomiso.id)

      this.$helper.showMessage('Quitar garantía', 'Se quitó relación de garantía con fideicomiso exitosamente.', 'success', 'alert')

      this.cancelar_seleccion()
      await this.garantiasFideicomiso()
    }

    , async guardar() {

      let isValid = await this.$validator({
        garantia_id: !this.garantia_fideicomiso.garantia_id ?  'required' : '',
        tipo_garantia_juridica: !this.garantia_fideicomiso.garantia_id ? 'required' : 'max:150',
      })


      if (isValid) {
        try {
          if (this.garantia_fideicomiso.id !== null) {
            await garantiasFideicomisoApi.editar(this.fideicomiso_id, this.garantia_fideicomiso.id,this.garantia_fideicomiso)
            this.$helper.showMessage('Editar garantía de fideicomiso', 'Información guardada exitosamente.', 'success', 'alert')

          } else {
            await garantiasFideicomisoApi.crear(this.fideicomiso_id,this.garantia_fideicomiso)
            this.$helper.showMessage('Agregar garantía a fideicomiso', 'Información guardada exitosamente.', 'success', 'alert')
          }
          this.cancelar_seleccion()
          await this.garantiasFideicomiso()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage((!this.cliente_id ? 'Guardar' : 'Editar') + ' garantía', 'Ocurrio un error al guardar datos de garantía, favor de reportarlo al administador.', 'error', 'alert')
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">

</style>
