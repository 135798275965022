
<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Fideicomisos</h1>
    </div>
    <br />
    <DataGridV3 :configuracion="dataGridConfig" :data="dataSourceGrid" :seleccionar="seleccionar" ref="gridFideicomisos"  @ready="cargarAcciones">
      <div class="row">
        <div class="col-sm-auto">
          <button class="btn btn-info" @click="cargar()"> <i class='fa fa-refresh'></i> Recargar</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('garantias_app','fideicomisos_crear')">
          <button  class="btn btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
        </div>
        <div class="col-sm-auto">
          <div class="btn-group mr-2" role="group">
            <button class="btn btn-primary dropdown-toggle" id="fideicomisos_filtro_fondeadores" type="button"  data-toggle="dropdown" aria-expanded="false">
              Filtrar por fondeador: {{!filtro_fondeador ? '' : filtro_fondeador.nombre}}
            </button>
            <div class="dropdown-menu" aria-labelledby="fideicomisos_filtro_fondeadores">
              <a class="dropdown-item" href="#" @click="cargar">Ningúno</a>
              <a class="dropdown-item" href="#" v-for="fondeador in fondeadores" @click="filtrar_fondeador(fondeador)">
                {{fondeador.nombre}}
              </a>
            </div>
          </div>
        </div>
      </div>

    </DataGridV3>

    <formulario-fideicomiso v-if="openModalForm" @cancelar="cancelar_seleccion" @actualizar="cargar" :prop_fideicomiso_id="itemSeleccionado.id" :prop_cliente_id="itemSeleccionado.persona_id"></formulario-fideicomiso>

    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminar fideicomiso </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el fideicomiso  {{ itemSeleccionado.no_fideicomiso }} <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import Modal from "@/components/Modal.vue";
import DataGridV3 from "@/components/DataGridV3.vue";
import FormularioCliente from "@/apps/garantias_app/pages/clientes/FormularioCliente.vue";
import Cliente from "@/apps/garantias_app/pages/clientes/Cliente.vue";
import fideicomisosApi from "@/apps/garantias_app/api/personas/fideicomisos/fideicomisos.api";
import FormularioFideicomiso from "@/apps/garantias_app/pages/fideicomisos/FormularioFideicomiso.vue";
import fondeadoresApi from "@/apps/garantias_app/api/fondeadores/fondeadores.api";

export default {
  name: "Fideicomisos",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      dataGridConfig: {
        name: 'garantias_fideicomisos',
        cols: {
          acciones: '',
          id: 'ID',
          external_id: 'ID externo',
          no_fideicomiso: 'No. de Fideicomiso',
          estatus: 'Estatus',
          cliente: 'Cliente',
        }
        ,filters: {
          cols: {
            external_id: 'ID externo',
            no_fideicomiso: 'No. de Fideicomiso',
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          acciones: function(val,row,vue) {
            let acciones = "<div class='text-center'>";
            if (vue.$auth.can('garantias_app','fideicomisos_editar'))
              acciones += "<i data-action='editar' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-acciones btn-sm btn-primary fa fa-edit mr-sm-2'></i>";

            return acciones+"</div>";
          },
          estatus:function (val){
            return val.toUpperCase()
          },
          cliente: function (val,row,vue){
            return row.persona.nombre_completo
          }
        },
        show_search: true,
        search: ['cliente', 'no_fideicomiso'],
        selector: false,
      },
      seleccionar: false,
      dataSourceGrid: [],
      accionesCargadas: false,
      itemSeleccionado: {
        id: null,
        persona_id: null,
        identificador_externo: null,
        no_fideicomiso: null
      }
      ,fondeadores: []
      ,filtro_fondeador: null
    }

  },
  components: {
    FormularioFideicomiso,
    FormularioCliente,
    Cliente,
    DataGridV3,
    Modal
  },
  computed: {

  },
  mounted() {
    this.cargar()
    this.cargar_fondeadores()
  },
  methods: {
    cargarAcciones() {
      let btns = document.querySelectorAll('.btn-acciones');
      btns.forEach(btn => {
        btn.removeEventListener('click', this.ejecutarAccionBtn )
        btn.addEventListener('click', this.ejecutarAccionBtn)
      })
    },
    ejecutarAccionBtn(e){
      let seleccion  = this.$helper.fromJson(e.target.dataset.opcion);
      if (seleccion.id !== this.itemSeleccionado.id) {
        let accion = e.target.dataset.action;
        this.itemSeleccionado = {...seleccion}
        this[accion](seleccion);
      }
    }
    ,cancelar_seleccion(){
      this.openModalForm = false;
      this.openModalEliminar = false;

      this.itemSeleccionado = {
        id: null,
        identificador_externo: null,
        no_fideicomiso: null
      };
    },
    crear(){
      this.cancelar_seleccion()
      this.openModalForm = true;
    },
    async editar(){
      this.openModalForm = true;
    },
    pre_eliminar(){
      this.openModalEliminar = true;

    },

    async eliminar(){
      this.openModalEliminar = false;
      await fideicomisosApi.eliminar(this.itemSeleccionado.persona_id,this.itemSeleccionado.id).then(() => {
        this.$helper.showMessage('Eliminar fideicomiso','Fideicomiso eliminado exitosamente.', 'success', 'alert')
        this.cargar()
        this.cancelar_seleccion()
      })
    },

    async cargar(options = {}) {
      options = {
        ...options
          ,relations:['persona:id,nombre,apellido_paterno,apellido_materno']
    }
      this.filtro_fondeador = null
      let dataSource = await fideicomisosApi.index(options)
      this.dataSourceGrid = dataSource.data
    }

    ,filtrar_fondeador(fondeador){

      this.cargar( {
        filters: [{
          relacion: "and",
          columna: "fondeadores.id",
          operador: "equal_to",
          valor1: fondeador.id,
          valor2: null
        }]
      })

      this.filtro_fondeador = fondeador
    }


    ,async cargar_fondeadores(){
      const options = {
        columns: 'id,nombre',
        filters: [{
          relacion: "and",
          columna: "estatus",
          operador: "not_equal_to",
          valor1: 'inactivo',
          valor2: null
        }]
      }
      const request = await fondeadoresApi.index(false, options)
      this.fondeadores = request.data
    }
  }

}
</script>


<style scoped lang="scss">
.btn-acciones:hover{
  cursor: pointer;
  filter: brightness(80%);
}
</style>