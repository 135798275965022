
<template>

  <div>

    <div class="container mb-5">
      <div class="text-right">
        <button class="btn btn-sm btn-success" @click="agregar" :disabled="input_solo_lectura"> <i class='fa fa-plus mr-sm-1'></i>Agregar cesionario</button>
      </div>
    </div>

    <div id="container-listado">
      <table class="table table-sm table-striped">
        <thead>
        <tr class="bg-dark">
          <th scope="col">
          </th>
          <th scope="col">Nombre</th>
          <th scope="col">Monto</th>
          <th scope="col">Fecha de cesión</th>
          <th scope="col">Estatus</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!cesionarios.length"><td colspan="9" class="text-secondary text-center"> No se encontraron cesionarios para mostrar.</td></tr>
        <tr v-for="(item, index) in cesionarios">
          <td>
            <button type="button" class="ml-3 btn btn-sm btn-primary" @click="editar(index)"><i :class="!input_solo_lectura ? 'fa fa-edit' : 'fa fa-eye'"></i></button>
          </td>
          <td>{{ item.nombre }}</td>
          <td>${{utilidades.moneyFormat(item.monto)}}</td>
          <td>{{item.fecha_cesion}}</td>
          <td class="text-uppercase">{{item.estatus}}</td>
        </tr>
        </tbody>
      </table>
    </div>



    <Modal v-if="openModalEliminarCesionario" :options="{width: '30vw',type:'error'}" @close="openModalEliminarCesionario = false">
      <div class="title">Eliminar cesionario</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el cesionario  {{ cesionario.nombre }}
            <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_eliminiar"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="formCesionario" :options="{width: '50vw',type:'info'}" @close="cerrar_modal_formulario">
      <div class="title">{{ !cesionario.id ? 'Agregar Cesionario' : 'Editar Cesionario'}}</div>
      <div class="body">

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col-12 form-group">
            <label for="nombre" class="form-label text-truncate d-block" title="Nombre">
              <i class="fa fa-check-circle text-primary"></i>
              Nombre
            </label>
            <div class="text-bold" v-if="input_solo_lectura"><b>{{cesionario.nombre}}</b></div>
            <input v-else v-model="cesionario.nombre" type="text" name="nombre" id="nombre" class="form-control">
          </div>

          <div class="col-12 form-group">
            <label for="nombre"class="form-label text-truncate d-block" title="Monto">
              <i class="fa fa-check-circle text-primary"></i>
              Monto
            </label>
            <div class="text-bold" v-if="input_solo_lectura"><b>{{cesionario.monto}}</b></div>
            <IC v-else v-model="cesionario.monto" label="$" id="monto" name="monto" :formats="['moneyFormat']"/>
          </div>

          <div class="col-12 form-group">
            <label for="fecha_cesion" class="form-label text-truncate d-block" title="Fecha Cesión">
              <i class="fa fa-check-circle text-primary"></i>
              Fecha Cesión
            </label>
            <div class="text-bold" v-if="input_solo_lectura"><b>{{cesionario.fecha_cesion}}</b></div>
            <input v-else v-model="cesionario.fecha_cesion" type="date" name="fecha_cesion" id="fecha_cesion" class="form-control">
          </div>

          <div class="col-12 form-group">
            <label for="cesionario_estatus" class="form-label text-truncate d-block" title="Estatus">
              <i class="fa fa-check-circle text-primary"></i>
              Estatus
            </label>
            <div class="text-bold" v-if="input_solo_lectura"><b>{{cesionario.estatus}}</b></div>
            <select v-else class="form-control" name="cesionario_estatus" id="cesionario_estatus"  v-model="cesionario.estatus" >
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>

        </div>

      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar" v-if="!input_solo_lectura"><i class="fa fa-check-square mr-sm-1"></i>{{ !cesionario.id ? 'Guardar' : 'Guardar Cambios'}}</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>
    </div>

</template>

<script>
import Modal from "@/components/Modal.vue";
import IC from "@/components/Form/InputCompuesto.vue";
import cesionariosApi from "@/apps/garantias_app/api/personas/fideicomisos/cesionarios/cesionarios.api";
import utilidades from "../../../../helpers/utilidades";

export default {
  name: "FideicomisoCesionarios"
  , props: {
    fideicomiso_id: null
    ,input_solo_lectura: {
      type: Boolean
      ,required: false
      ,default() {
        return false
      }
    }
  }
  , components: {IC, Modal}
  , data() {
    return {
      cesionarios: []
      , formCesionario: false
      , index_cesionario_seleccionado: null
      , openModalEliminarCesionario: false
    }
  }
  , mounted() {


    this.cargarListado()

  }
  , computed: {
    utilidades() {
      return utilidades
    },
    cesionario() {
      return this.index_cesionario_seleccionado == null ? {
        id: null
        , nombre: null
        , monto: null
        , fecha_cesion: null
        , estatus: null
      } : {...this.cesionarios[this.index_cesionario_seleccionado]}
    }

  }
  , methods: {

    agregar() {
      this.formCesionario = true
    },

    async cargarListado() {
      const filtros = {
        filters: [{
          relacion: "and",
          columna: "fideicomiso_id",
          operador: "equal_to",
          valor1: this.fideicomiso_id,
          valor2: null
        }]
      }
      const dataSource = await cesionariosApi.index(false, filtros)
      this.cesionarios = dataSource.data
    },

    editar(index) {
      this.index_cesionario_seleccionado = index
      this.formCesionario = true
    },

    cerrar_modal_formulario() {
      this.index_cesionario_seleccionado = null
      this.formCesionario = false
    }

    , cancelar_seleccion() {
      this.formCesionario = false;
      this.openModalEliminarCesionario = false;

      this.index_cesionario_seleccionado = null
    }

    , cancelar_eliminiar() {
      this.index_cesionario_seleccionado = null
      this.openModalEliminarCesionario = false
    }
    , pre_eliminar(index) {
      this.index_cesionario_seleccionado = index
      this.openModalEliminarCesionario = true
    }

    , async eliminar() {

      if (this.cesionario.hasOwnProperty('id') && this.cesionario.id !== null)
        await cesionariosApi.eliminar(this.fideicomiso_id, this.cesionario.id)

      this.cesionarios.splice(this.index_cesionario_seleccionado, 1)

      this.index_cesionario_seleccionado = null
      this.openModalEliminarCesionario = false
      this.$helper.showMessage('Eliminar', 'Información eliminada exitosamente.', 'success', 'alert')
    }

    , async guardar() {

      let isValid = await this.$validator({
        nombre: !this.cesionario.nombre ? 'required' : 'max:150',
        monto: !this.cesionario.monto ? 'required' : '',
        fecha_cesion: !this.cesionario.fecha_cesion ? 'required' : '',
        cesionario_estatus: !this.cesionario.estatus ? 'required' : '',
      })


      if (isValid) {
        try {
          if (this.cesionario.id !== null) {
            const request = await cesionariosApi.editar(this.fideicomiso_id, this.cesionario.id, this.cesionario)
            this.$helper.showMessage('Editar cesionario', 'Información guardada exitosamente.', 'success', 'alert')

          } else {
            const request = await cesionariosApi.crear(this.fideicomiso_id, this.cesionario)
            this.$helper.showMessage('Crear cesionario', 'Información guardada exitosamente.', 'success', 'alert')
          }
          this.cancelar_seleccion()
          await this.cargarListado()

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage((!this.cliente_id ? 'Guardar' : 'Editar') + ' cesionario', 'Ocurrio un error al guardar datos del cesionario, favor de reportarlo al administador.', 'error', 'alert')
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">
#container-listado{
  max-height: 400px;
  overflow-y: scroll;

  table th:first-child{
    width: 110px;
  }
}
</style>
