
<template>

  <div>

    <Modal  :options="{width: '90vw'}">
      <div class="title h2 text-white">{{ !fideicomiso_id ? 'Agregar Fideicomiso' : 'Editar Fideicomiso' }}</div>

      <div class="body">
        <div class="container-fluid">

          <div class="row justify-content-center mt-5" v-if="!editar">

            <div class="col-sm-6 form-group">
              <div class="h6 text-bold"><b>Buscar y seleccionar el cliente del fideicomiso</b></div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Buscar cliente..." v-model="busqueda_cliente" @input="buscar_cliente">
              </div>

              <div class="list-group text-left" v-if="on_search">
                <a href="#" class="list-group-item list-group-item-action" @click="seleccionar_cliente(cliente)" v-for="cliente in clientes">{{cliente.nombre_completo}}</a>
              </div>

            </div>

            <div class="col-sm-6 form-group">
              <label class="form-label"><b>Seleccionar crédito</b></label>
              <select class="form-control" name="credito" id="credito"  v-model="credito_id" :disabled="on_search || !cliente_id">
                <option :value="credito.id" v-for="credito in creditos">{{credito.external_id}}</option>
              </select>
            </div>



          </div>


          <div v-if="credito_id && formulario_cargado">
            <br>

            <div v-if="solo_lectura" class="text-right mb-4">
              <h5><span class="badge badge-warning">Estatus: {{estatus}}</span></h5>
            </div>

            <div class="text-right mb-5" v-if="editar">
              <button class="btn btn-sm btn-info"  @click="descargar_estado_cuenta()"> <i class='fa fa-download mr-sm-1'></i>Descargar estatus de fideicomiso</button>
            </div>

            <div class="text-right mb-5" v-if="ver_boton_cargar_origen" >
              <button class="btn btn-sm btn-primary" @click="pre_cargar_origen()"> <i class='fa fa-external-link mr-sm-1'></i>Cargar desde origen externo</button>
            </div>

            <div class="text-center my-4">
              <div class="h5">Información del Fideicomiso</div>
              <div class="h6" v-if="editar"><b>Cliente: {{fideicomiso.persona.nombre_completo}}</b></div>
              <div class="h6 text-secondary" v-if="editar"><b>ID de Fideicomiso: {{fideicomiso.id}}</b></div>
            </div>

            <div class="alert alert-warning" role="alert" v-if="solo_lectura">
             <i class="fa fa-exclamation-triangle mr-sm-1"></i> <b>Información de solo lecura:</b>  El estatus del fideicomiso o el crédito del fideicomiso tiene acceso reestringido a la edición de información del fideicomiso, se deshablitan formularios y acciones, solo se podrá editar el estatús del
              fideicomiso si el estatús del crédito lo permite.
            </div>

            <div v-else class="alert alert-primary mb-5" role="alert">
              <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan los iconos:
              <ul class="list-group border-0 mt-2">
                <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-external-link mx-sm-1"></i> indican que fueron cargados desde un origen externo, si edita los valores obtenidos ya no podrán recuperarse.</li>
                <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-refresh mx-sm-1"></i> hacen referencia a campos dinámicos configurados por el administrador y pueden cambiar en cualquier momento.</li>
                <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-check-circle mx-sm-1"></i> indica que el campo es obligatorio.</li>
              </ul>
            </div>

            <div  class="row">

              <div class="col-sm-3 form-group">
                <label for="identificador_externo"  class="form-label text-truncate d-block" title="Indetificador externo">
                  Identificador externo
                </label>
                <div class="text-bold" v-if="solo_lectura">
                  <i v-if="!fideicomiso.identificador_externo" class="text-danger">Valor no ingresado</i>
                  <b v-else>{{fideicomiso.identificador_externo}}</b>
                </div>
                <input v-else v-model="fideicomiso.identificador_externo" type="text" name="identificador_externo" id="identificador_externo" class="form-control" :disabled="on_search">
              </div>

              <div class="col-sm-3 form-group">
                <label for="no_fideicomiso"  class="form-label text-truncate d-block" title="No. de fideicomiso">
                  <i class="fa fa-check-circle text-primary"></i>
                  No. del fideicomiso
                </label>
                <div class="text-bold" v-if="solo_lectura"><b>{{fideicomiso.no_fideicomiso}}</b></div>
                <input v-else v-model="fideicomiso.no_fideicomiso" type="text" name="no_fideicomiso" id="no_fideicomiso" class="form-control" :disabled="on_search">
              </div>

              <div class="col-sm-3 form-group">
                <label for="estatus"  class="form-label text-truncate d-block" title="Estatus">
                  <i class="fa fa-check-circle text-primary"></i>
                  Estatus
                </label>
                <select class="form-control" name="estatus" id="estatus"  v-model="fideicomiso.estatus" >
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </select>
              </div>

              <div class="col-sm-3 form-group" v-for="campo in campos_dinamicos ">

                <template v-if="solo_lectura">
                  <label  class="form-label text-truncate d-block" :title="campo.nombre">
                    {{ campo.nombre }}
                  </label>
                  <div class="text-bold">
                    <i v-if="!fideicomiso[campo.clave]" class="text-danger">Valor no ingresado</i>
                    <b v-else>{{fideicomiso[campo.clave]}}</b>
                  </div>
                </template>

                <template v-else>
                  <campo-dinamico v-if="campo.catalogo_id"
                                  :label="campo.nombre"
                                  :clave="campo.clave"
                                  :tipo="campo.tipo_dato"
                                  :valor="fideicomiso[campo.clave]"
                                  :opciones_select="campo.catalogo.opciones"
                                  :select_clave_valor="true"
                                  :select_opciones_label="'nombre'"
                                  :select_opciones_valor="'valor'"
                                  v-model="fideicomiso[campo.clave]"
                                  :disabled="on_search"
                                  :required="campo.campo_requerido"
                  >
                    <template v-slot:label>
                      <button  v-if="campo.es_de_origen_externo" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
                    </template>
                  </campo-dinamico>

                  <campo-dinamico v-else :label="campo.nombre"
                                  :clave="campo.clave"
                                  :tipo="campo.tipo_dato"
                                  :valor="fideicomiso[campo.clave]"
                                  :opciones_select="campo.tipo_dato !== 'array' ?  [] : campo.valores"
                                  v-model="fideicomiso[campo.clave]"
                                  :disabled="on_search"
                                  :required="campo.campo_requerido"
                  >
                    <template v-slot:label>
                      <button  v-if="campo.es_de_origen_externo" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
                    </template>
                  </campo-dinamico>
                </template>

              </div>

            </div>

            <div class="card mt-5 border-0" v-if="editar">
              <div class="card-body">
                <ul class="nav nav-pills nav-fill text-center mb-1" style="border-bottom: solid 1px #e0e0e0">
                  <li class="nav-item">
                    <a class="nav-link rounded-0" :class="(tab_activa === 'tab-garantias') && 'active'" aria-current="page" href="#" @click="change_tab('tab-garantias')"><i class="fa fa-home mr-sm-2"></i> Garantías </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link rounded-0" :class="(tab_activa === 'tab-cesionarios') && 'active'" aria-current="page" href="#" @click="change_tab('tab-cesionarios')"><i class="fa fa-handshake mr-sm-2"></i> Cesionarios </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link rounded-0" :class="(tab_activa === 'tab-honorarios-fiduciarios') && 'active'" aria-current="page" href="#" @click="change_tab('tab-honorarios-fiduciarios')"><i class="fa fa-money-bill mr-sm-2"></i> Honorarios Fiduciarios </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link rounded-0" :class="(tab_activa === 'tab-beneficiarios') && 'active'" aria-current="page" href="#" @click="change_tab('tab-beneficiarios')"><i class="fa fa-users mr-sm-2"></i> Beneficiarios </a>
                  </li>
                </ul>

                <div class="tab-content" id="nav-tabContent">
                  <div class="container tab-pane fade mt-4 pt-4" :class="(tab_activa === 'tab-garantias') && 'show active'" v-if="tab_activa === 'tab-garantias'">
                    <fideicomiso-garantias :fideicomiso_id="fideicomiso_id"  :cliente_id="cliente_id" :credito_id="credito_id" :input_solo_lectura="solo_lectura"></fideicomiso-garantias>
                  </div>

                  <div class="container tab-pane fade mt-4 pt-4" :class="(tab_activa === 'tab-cesionarios') && 'show active'" v-if="tab_activa === 'tab-cesionarios'">
                   <fideicomiso-cesionarios :fideicomiso_id="fideicomiso_id" :input_solo_lectura="solo_lectura"></fideicomiso-cesionarios>
                  </div>

                  <div class="container tab-pane fade  mt-4 pt-4" :class="(tab_activa === 'tab-honorarios-fiduciarios') && 'show active'" v-if="tab_activa === 'tab-honorarios-fiduciarios'">
                    <HonorariosFiduciarios :fideicomiso_id="fideicomiso_id" :input_solo_lectura="solo_lectura"></HonorariosFiduciarios>
                  </div>

                  <div class="container tab-pane fade  mt-4 pt-4" :class="(tab_activa === 'tab-beneficiarios') && 'show active'" v-if="tab_activa === 'tab-beneficiarios'">
                    <fideicomiso-beneficiarios :fideicomiso_id="fideicomiso_id" :input_solo_lectura="solo_lectura"></fideicomiso-beneficiarios>
                  </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="$emit('cancelar')"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          <div class="col-auto" v-if="!solo_lectura || cambio_estatus_a_inactivo"><button type="button" class="btn btn-success" @click="guardar" id="btn-crear" name="btn-crear" :disabled="!credito_id"><i class='fa fa-save mr-sm-1'></i>Guardar
            {{ !cliente_id ? '' : 'cambios' }}</button></div>
        </div>
      </div>


    </Modal>

    <Modal v-if="modalConfirmarCambioCredito" :options="{width: '30vw',type:'error'}" >
      <div class="title">Cambió crédito seleccionado</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            El formulario de fideicomiso puede cargar información relacionada directamente al crédito seleccionado o haber sido cargada desde un origen externo,
            si este cambia el formulario se cargará nuevamente y perderá toda la información ingresada.
            <br/>¿Esta seguro de continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-primary" @click="resetFormulario"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
            <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_cambio_credito"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          </div>
        </div>
      </div>
    </Modal>


    <!--MODAL DESACTIVAR REGISTRO-->
    <modal-desactivar-registro
        v-if="modalConfirmarCambioEstatusLectura"
        :estatus="estatus" titulo="Cambiar estatus del fideicomiso a solo lectura"
        @continuar="confirmar_cambio_estatus_lectura(true)"
        @cancelar="confirmar_cambio_estatus_lectura(false)"
    ></modal-desactivar-registro>


    <!--COMPONENTES CARGAR ORIGEN EXTERNO -->
    <FormularioParamsOrigen v-if="form_parametros_cargar_origen_datos" :origen="configuracion_origen_datos" @enviar="form_origen_enviar($event)" @cancelar="cancelar_form_params_origen()"></FormularioParamsOrigen>
    <FormulatioSeleccionarOrigen v-if="form_seleccion_origen_de_datos" :response_origen="origen_datos_response" @cancelar="cancelar_form_seleccion_origen()" @seleccion="asignar_valores_origen($event)"></FormulatioSeleccionarOrigen>

  </div>


</template>

<script>
import personasApi from "@/apps/garantias_app/api/personas/personas.api";
import CampoDinamico from "@/apps/garantias_app/components/CampoDinamico.vue";
import Modal from "@/components/Modal.vue";
import fideicomisosApi from "@/apps/garantias_app/api/personas/fideicomisos/fideicomisos.api";
import creditosApi from "@/apps/garantias_app/api/personas/creditos/creditos.api";
import CreditosCliente from "@/apps/garantias_app/pages/clientes/CreditosCliente.vue";
import DomicliosCliente from "@/apps/garantias_app/pages/clientes/DomiciliosCliente.vue";
import Log from "@/apps/garantias_app/components/Log.vue";
import FideicomisoCesionarios from "@/apps/garantias_app/pages/fideicomisos/FideicomisoCesionarios.vue";
import HonorariosFiduciarios from "@/apps/garantias_app/pages/fideicomisos/HonorariosFiduciarios.vue";
import FideicomisoBeneficiarios from "@/apps/garantias_app/pages/fideicomisos/FideicomisoBeneficiarios.vue";
import FideicomisoGarantias from "@/apps/garantias_app/pages/fideicomisos/FideicomisoGarantias.vue";
import axios from "axios";
import FormulatioSeleccionarOrigen from "@/apps/garantias_app/components/FormularioSeleccionOrigen.vue";
import FormularioParamsOrigen from "@/apps/garantias_app/components/FormularioParamsOrigen.vue";
import origenDatosApi from "@/apps/garantias_app/api/personas/origen_datos.api";
import ModalDesactivarRegistro from "@/apps/garantias_app/components/ModalDesactivarRegistro.vue";

export default {
  name: "FormularioFideicomiso"
  ,props:{
    prop_cliente_id: {
      type: null | String
      ,required: false
      ,default() {
        return null
      }
    },
    prop_fideicomiso_id: {
      type: null | String
      ,required: false
      ,default() {
        return null
      }
    },
  }
  ,components: {
    ModalDesactivarRegistro,
    FormularioParamsOrigen, FormulatioSeleccionarOrigen,
    FideicomisoGarantias,
    FideicomisoBeneficiarios,
    HonorariosFiduciarios,
    FideicomisoCesionarios, Log, DomicliosCliente, CreditosCliente, Modal, CampoDinamico}
  ,data(){
    return {
      fideicomiso: {
        no_fideicomiso:null
        ,identificador_externo: null
        ,persona_credito_id: null
        ,propiedades: [] // campos dinamicos del fideicomiso
        ,estatus: null
        ,solo_lectura: false
      }
      ,campos_dinamicos: []
      ,credito_id_anterior: null // contiene el valor del crédito anterior a la selección actual
      ,estatus_fideicomiso_db: null // contiene el valor del estatus del fideicomiso en edición como lo retorna el api al obtener el fideicomiso
      ,credito_id: null
      ,busqueda_cliente: ''
      ,clientes: []
      ,creditos: []
      ,on_search:false
      ,cliente_id: null
      ,fideicomiso_id: null
      ,modalConfirmarCambioCredito: false
      ,formulario_cargado: false
      ,tab_activa: 'tab-garantias'
      ,personaCancelTokenSource: null
      ,configuracion_origen_datos: {
        id: null,
        identificador: null,
        origen_asincrono: false,
        parametros_request:[],
        campos:[] //Las propiedades que estan configuradas para ser cargadas desde el origen
      },
      form_parametros_cargar_origen_datos: false,
      form_seleccion_origen_de_datos: false
      ,origen_datos_response:[]
      ,modalConfirmarCambioEstatusLectura:false
    }
  }
  ,mounted() {

    if (typeof this.prop_fideicomiso_id == "string")
      this.fideicomiso_id = this.prop_fideicomiso_id

    if (typeof this.prop_cliente_id == "string")
      this.cliente_id = this.prop_cliente_id

    this.cargar_data()
  }
  ,computed:{
    validaciones_fideicomiso(){

      let validaciones = {
        identificador_externo: !!this.fideicomiso.identificador_externo ? 'max:255' : '',
        no_fideicomiso: !this.fideicomiso.identificador_externo ? 'required' : 'max:255',
        estatus: !this.fideicomiso.estatus ? 'required' : '',
      }

      for (const campo of this.campos_dinamicos) {
        validaciones[campo.clave] = !this.fideicomiso[campo.clave] && campo.campo_requerido ? 'required' : (campo.tipo_dato === 'string' ? 'max:255' : '')
      }

      return validaciones
    }
    ,editar(){
      return typeof this.fideicomiso.id == 'string';
    }
    ,estatus(){
      return this.fideicomiso.estatus ? this.fideicomiso.estatus.toLowerCase() : ''
    }

    , solo_lectura(){
      if(this.fideicomiso.hasOwnProperty('solo_lectura'))
        return this.fideicomiso.solo_lectura

      return this.estatus_solo_lectura(this.estatus)
    }

    ,cambio_estatus_a_inactivo(){
      return !this.estatus_solo_lectura(this.estatus_fideicomiso_db) && this.estatus_solo_lectura(this.estatus)
    }

    ,ver_boton_cargar_origen(){
      return !this.editar && this.configuracion_origen_datos.origen_asincrono
    }
  }
  ,methods:{

    estatus_solo_lectura(estatus){
      estatus = !estatus ? estatus : estatus.toLowerCase()
      return (estatus === 'reestructurado' || estatus === 'inactivo')
    },

    change_tab(tab){
      this.tab_activa = tab
    },


    async cargar_data(){



        if (typeof this.fideicomiso_id == "string") {
          const dataSource = await fideicomisosApi.obtener(this.cliente_id,this.fideicomiso_id)
          this.fideicomiso = dataSource.data

          this.credito_id = this.fideicomiso.persona_credito_id
          this.fideicomiso.identificador_externo = this.fideicomiso.external_id ?? null
          this.estatus_fideicomiso_db = this.fideicomiso.estatus

      }else{
          await this.validar_origen()
        }

      await this.cargarCampos()

      this.formulario_cargado = true
    }

    ,async buscar_cliente(){

      this.on_search = (this.busqueda_cliente.length > 2)

      if (this.on_search){


        //Cancela busqueda anterior
        if (this.personaCancelTokenSource) {
          this.personaCancelTokenSource.cancel('Se cancela busqueda anterior, nueva busqueda activa');
        }

        //Asigna source de token de cancelación de request
        this.personaCancelTokenSource = axios.CancelToken.source();

        const filtros = { limit: 5, search: this.busqueda_cliente}
        await personasApi.index(false, filtros, this.personaCancelTokenSource.token).then(response => {
          this.clientes = response.data
          this.personaCancelTokenSource = null;
        })

      }

    }
    ,seleccionar_cliente(cliente){
      this.on_search = false
      this.credito_id = null
      this.busqueda_cliente = cliente.nombre_completo
      this.cliente_id = cliente.id
      this.obtener_creditos()
    }

    ,async obtener_creditos(){
      const options = {
        filters: [{
          relacion: "and",
          columna: "estatus",
          operador: "not_equal_to",
          valor1: 'inactivo',
          valor2: null
        }]
      }

      const dataSource = await creditosApi.index(this.cliente_id, options)
      this.creditos = dataSource.data

      if (!this.creditos.length)
        this.$helper.showMessage('Créditos cliente', 'No se encontrarón créditos asociados al cliente seleccionado.', 'warn', 'alert')
    }
    , resetFormulario(){

      this.formulario_cargado = false
      this.fideicomiso = {
        no_fideicomiso:null
        ,identificador_externo: null
        ,persona_credito_id: null
        ,propiedades: [] // campos dinamicos del fideicomiso
        ,estatus: null
      }
      this.campos_dinamicos = []
    }

    ,cancelar_cambio_credito(){
      this.modalConfirmarCambioCredito = false
      this.credito_id = this.credito_id_anterior
    }
    ,async cargarCampos(){
      let dataSource = await fideicomisosApi.campos_dinamicos()
      this.campos_dinamicos = dataSource.data

      for (const campoKey in this.campos_dinamicos) {

        const campo = this.campos_dinamicos[campoKey]

        /**
         * Si no esta editando un fideicomiso se descartan los campos dinámicos que no esten activos para
         * el nuevo registro
         */
        if (!this.editar){
          if (!campo.activo) {
            this.campos_dinamicos.splice(campoKey,1)
          }else
            this.fideicomiso[campo.clave] = null

          continue
        }

        let propiedad = {}

        /**
         * Validar si se esta editando un registro y buscar en sus propiedades los valores de los campos dinamicos
         * las propiedades se obtienen al consultar el registro en el api y se asignan sus valores
         * */
        if(this.fideicomiso.propiedades){
          propiedad = this.fideicomiso.propiedades.find(propiedad => propiedad.campo_id === campo.id)

          /**
           * Se omiten los campos dinámicos que esten inactivos si la propiedad para el mismo no existe en
           * las propiedades del registro en edición
           */
          if (!campo.activo && !propiedad) {
            this.campos_dinamicos.splice(campoKey,1)
            continue
          }
        }

        this.fideicomiso[campo.clave] = propiedad && propiedad.hasOwnProperty('valor')  ? propiedad.valor : null
      }
    }
    ,async guardar() {
      let isValid = await this.$validator(this.validaciones_fideicomiso)

      let payload = {...this.fideicomiso, credito: this.credito_id}

      if (isValid) {

        try {
          if (this.editar) {
            await fideicomisosApi.editar(this.cliente_id, this.fideicomiso.id, payload)
            //Se asigna el valor actualizado del estatus a estatus_fideicomiso_db
            this.estatus_fideicomiso_db = this.fideicomiso.estatus
            this.$helper.showMessage('Editar fideicomiso', 'Información de fideicomiso guardada exitosamente.', 'success', 'alert')

          } else {
            let request = await fideicomisosApi.crear(this.cliente_id,payload)
            this.fideicomiso = {identificador_externo: request.data.external_id, ...request.data}
            this.fideicomiso_id = this.fideicomiso.id

            this.$helper.showMessage('Crear fideicomiso', 'Información de fideicomiso guardada exitosamente.', 'success', 'alert')
            await this.cargarCampos()
          }

          this.$emit('actualizar')

        }catch(e){
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage((!this.fideicomiso.id ? 'Guardar' : 'Editar') + ' fideicomiso', 'Ocurrio un error al guardar datos del fideicomiso, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    }

    ,async descargar_estado_cuenta(){
      try {
        let request = (await fideicomisosApi.descargar_estado_cuenta(this.fideicomiso.id)).data;

        let url = window.URL.createObjectURL(new Blob([request]));
        let link = document.createElement('a');
        let filename = 'estatus_fideicomiso_'+this.fideicomiso.no_fideicomiso+'.pdf'
        link.href = url;
        link.setAttribute('download',filename);
        document.body.appendChild(link);
        link.click();

      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }

    /**
     * Valida si hay un origen de datos para el formulario y habilitar botón para cargar origen de datos
     * Para que la asignación de la propiedad es_de_origen_externo sea correcta para los campos dinámicos
     * primero se debieron haber cargado los campos dinámicos del formulario.
     * @returns {Promise<void>}
     */
    , async validar_origen() {

      const request = await origenDatosApi.get_origen('formulario_fideicomiso')
      if (request.data[0] && request.data[0].origen_asincrono) {
        // Hablilita botón para ingresar datos necesarios para cargar origen de datos
        this.configuracion_origen_datos =  request.data[0]
      }

      //Validar que campos van a tener el ícono de origen externo en el formulario tanto para los campos dinámicos
      if (this.configuracion_origen_datos.campos){
        for (const campo_origen of this.configuracion_origen_datos.campos) {
          //Se asigna la propiedad es_de_origen_externo a los campos dinámicos que esten en la configuración de campos de origen externo
          const index_campo_dinamico = this.campos_dinamicos.findIndex( campo_dinamico => campo_dinamico.id = campo_origen.campo_id)
          if (index_campo_dinamico >= 0){
            this.campos_dinamicos[index_campo_dinamico].es_de_origen_externo = true
          }
        }
      }

    }

    ,pre_cargar_origen() {
      if (this.configuracion_origen_datos.parametros_request.length > 0) {
        this.form_parametros_cargar_origen_datos = true
      }else
        this.cargar_origen()
    }

    ,form_origen_enviar(event){
      this.form_parametros_cargar_origen_datos = false
      this.cargar_origen(event)
    }

    , async cargar_origen(request_params = {}) {
      this.origen_datos_response = []
      const dataSource = await fideicomisosApi.formulario(this.credito_id,request_params)
      this.origen_datos_response = dataSource.data

      //Activa formulario donde muestra resultados del origen para seleccionar
      this.form_seleccion_origen_de_datos = true

    },

    asignar_valores_origen(data){

      this.form_seleccion_origen_de_datos = false

      const campos_dinamicos_valores = !data.campos_dinamicos ? [] : data.campos_dinamicos

      //Asignar valores a propiedades de garantía (valores_origen contiene los campos que no son dinámicos)
      for (const [key, value] of Object.entries(data.valores_origen)) {

        if (this.fideicomiso.hasOwnProperty(key)) {
          this.fideicomiso[key] = value
        }
      }
      //Asignar valores de campos dinámicos
      for (const campo of campos_dinamicos_valores) {
        if (this.fideicomiso.hasOwnProperty(campo.clave)) {
          let valor = campo.valor_origen ? campo.valor_origen : null;

          if (campo.tipo_dato === 'date' && !!valor) {
            valor = this.$moment(valor).format("YYYY-MM-DD")
          }

          this.fideicomiso[campo.clave] = valor
        }
      }

    }

    ,cancelar_form_seleccion_origen(){
      this.form_seleccion_origen_de_datos= false
    }
    ,cancelar_form_params_origen(){
      this.form_parametros_cargar_origen_datos = false
    }

    /**
     * Confirma cambio de estaus a uno de solo lectura, si el valor de la confirmación es falso
     * @param confirmacion
     */
    ,confirmar_cambio_estatus_lectura(confirmacion){
      console.log('confirmar_cambio_estatus_lectura',confirmacion)
      if (!confirmacion)
        this.fideicomiso.estatus = this.estatus_fideicomiso_db

      this.modalConfirmarCambioEstatusLectura = false
    }
  }

  ,watch: {
    'credito_id': {
      handler(newValue, oldValue) {
        this.credito_id_anterior = oldValue
        if (oldValue !== null) {
          if ((newValue !== oldValue))
            this.modalConfirmarCambioCredito = true
        }
      }
    }
    ,'fideicomiso.estatus': {
      handler(newValue, oldValue) {
        //Si se edita el estaus de un fideicomiso ya guardado por un estatus de solo lectura abre modal para confirmar cambio
        if (this.cambio_estatus_a_inactivo) {
          this.modalConfirmarCambioEstatusLectura = true
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
