
<template>

  <div>
    <Modal :options="{width: '40vw'}">

      <div class="title h2 text-white">{{ !contacto.id ? 'Agregar' : 'Editar' }} Contacto</div>

      <div class="body">

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
        </div>

        <div class="row justify-content-center">

          <div class="col-sm-4 form-group">
            <label for="tipo_contacto"  class="form-label text-truncate d-block" title="Medio de Contacto">
              <i class="fa fa-check-circle text-primary"></i>
              Medio de Contacto
            </label>
            <select class="form-control" name="tipo_contacto" id="tipo_contacto"  v-model="contacto.tipo_contacto" >
              <option :value="tipo_contacto.value" v-for="tipo_contacto in tipos_contacto">{{tipo_contacto.label}}</option>
            </select>
          </div>

          <div class="col-sm-4 form-group">
            <label for="tipo"  class="form-label text-truncate d-block" title="Tipo">
              <i class="fa fa-check-circle text-primary"></i>
              Tipo
            </label>
            <select class="form-control" name="tipo" id="tipo"  v-model="contacto.tipo" >
              <option :value="tipo.value" v-for="tipo in tipos">{{tipo.label}}</option>
            </select>
          </div>

          <div class="col-sm-4 form-group">
            <label for="valor"  class="form-label text-truncate d-block" title="Contacto">
              <i class="fa fa-check-circle text-primary"></i>
              Contacto
            </label>
            <input v-model="contacto.valor" type="tel" name="valor" id="valor" class="form-control" maxlength="10" v-if="contacto.tipo_contacto == 'telefono'">
            <input v-else v-model="contacto.valor" type="text" name="valor" id="valor" class="form-control">
          </div>

        </div>


      </div>

      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="$emit('cancelar')"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          <div class="col-auto"><button type="button" class="btn btn-success" @click="guardar" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>Guardar {{!contacto.id ? '' : 'cambios'}}</button></div>
        </div>
      </div>
    </Modal>

  </div>





</template>

<script>


import Modal from "@/components/Modal.vue";
import personasContactosApi from "@/apps/garantias_app/api/personas/contactos.api";
export default {
  name: "FormularioContacto"
  ,components: {Modal}
  ,props: {
    persona: {
      type: String | null,
      required: true,
      default() {
        return null;
      }
    },
    input_contacto: {
      type: Object,
      required: false,
      default() {
        return {
          id: null
          ,tipo_contacto: 'telefono'
          ,tipo: null
          ,valor: null
        }
      }
    }
  }
  ,data() {
    return {
      tipos_contacto: [
        { label: "Teléfono",  value: "telefono" },
        { label: "Correo",     value: "correo" }
      ]
      ,tipos: [
        { label: "Principal",  value: "principal" },
        { label: "Secundario",  value: "secundario" },
        { label: "Trabajo",     value: "trabajo" },
        { label: "Personal",     value: "personal" }
      ]
      ,contacto:{
        id: null
        ,tipo_contacto: 'telefono'
        ,tipo: null
        ,valor: null
      }

    }
  }
  , mounted: function () {
    if (this.input_contacto.hasOwnProperty('id') && this.input_contacto.id != null)
      this.contacto = this.input_contacto
  }
  ,computed:{
  }
  ,methods:{

    cancelar(){
      this.$emit('cancelar')
    }
    ,async guardar(){

      let validaciones_tipo_contacto = {telefono: 'phone', 'correo': 'email|max:100'}

      let isValid = await this.$validator({
        tipo_contacto: !this.contacto.tipo_contacto ? 'required' : '',
        tipo: !this.contacto.tipo ? 'required': '',
        valor: !this.contacto.valor ? 'required' : validaciones_tipo_contacto[this.contacto.tipo_contacto],
      })

      if (isValid) {
        try {
          if (this.contacto.id != null) {
            await personasContactosApi.editar(this.persona,this.contacto.id, this.contacto)
            this.$helper.showMessage('Crear contacto', 'Información de contacto guardada exitosamente.', 'success', 'alert')

          }else{
            await personasContactosApi.crear(this.persona, this.contacto)
            this.$helper.showMessage('Editar contacto', 'Información de contacto guardada exitosamente.', 'success', 'alert')
          }

          this.$emit('actualizar')

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( (!this.id ? 'Guardar' : 'Editar')+ ' contacto', 'Ocurrio un error al guardar contacto del cliente, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    }

  }
}
</script>

<style scoped lang="scss">

</style>
